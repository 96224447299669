<template>
  <div>
    <mainHeader />
    <mainNavigator />

    <div class="main">
      <div class="py-container">
        <!--bread-->
        <div class="bread">
          <ul class="fl sui-breadcrumb">
            <li>
              <a @click="$router.push('/')">首页</a>
            </li>
            <li class="active">{{ type || "专题" }}</li>
          </ul>
          <!-- <ul class="fl sui-tag">
            <li class="with-x">
              iphone
              <i>×</i>
            </li>
          </ul>
          <form class="fl sui-form form-dark">
            <div class="input-control control-right">
              <input type="text" />
              <i class="sui-icon icon-touch-magnifier"></i>
            </div>
          </form> -->
        </div>

        <div class="zc-sub-filter" v-if="$route.query.type !== '新品首发'">
          <ul>
            <li :class="{ active: sort == 'sort_order' }" @click="onSort('sort_order', 'desc')">综合</li>
            <li :class="{ active: sort == 'sales' }" @click="onSort('sales', 'asc')">销量</li>
            <li class="zc-filter-order" :class="{ active: sort == 'retail_price', asc: order == 'asc', desc: order == 'desc' }" @click="onSort('retail_price')">价格</li>
          </ul>
          <!-- <div class="zc-sub-search">
            <input type="text" v-model="keywords" placeholder="请输入商品标题/商品编码" @change="onSearch(keywords)" />
            <img :src="require('../../../assets/images/search.png')" @click="onSearch(keywords)" />
          </div> -->
          <div class="search-r1">
            <div class="search-r2">
              ￥
              <input class="search-r2a" v-model="minPrice" @blur="minPriceInput" type="text" />
            </div>
            <span class="search-r3"></span>
            <div class="search-r4">
              <div class="search-r2">
                ￥
                <input class="search-r2a" v-model="maxPrice" @blur="maxPriceInput" type="text" />
              </div>
              <img class="search-r5" :src="require('../../../assets/images/search.png')" @click="onSearch()" />
            </div>
          </div>
        </div>
        <!--selector-->
        <!-- <div class="clearfix selector">
          <div class="type-wrap">
            <div class="key">{{ navTitle }}</div>
            <div class="value">
              <ul class="type-list">
                <li v-for="(item, index) in navList" :key="index" :class="id == item.id ? 'active' : ''" :data-id="item.id" :data-index="index" @click="switchCate">
                  <a>{{ item.name }}</a>
                </li>
              </ul>
            </div>
            <div class="fl ext"></div>
          </div>
        </div> -->
        <!--details-->
        <div class="details">
          <!-- <div class="goods-list" v-if="toast1">
            <el-empty description="加载中"></el-empty>
          </div> -->
          <div class="goods-list">
            <p class="zc-panel-empty" v-if="!topicGoods || !topicGoods.length">
              <img :src="require('@/assets/images/n2.png')" />
              <strong>暂无商品</strong>
            </p>
            <div v-else>
              <ul class="partA-wrap">
                <li class="partA" @click="jumpGoods(item)" v-for="(item, index) in topicGoods" :key="index">
                  <div class="partAa">
                    <img class="partAa-a" :src="item.picUrl" alt="" />
                    <div class="partAa-b">{{ item.name }}</div>
                    <div class="partAa-c">
                      ￥
                      <b>{{ item.price }}</b>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="zc-panel-footer" v-if="$route.query.type !== '新品首发'">
                <commonPagination :total="totalCount" :load="TopicDetail"></commonPagination>
              </div>
            </div>
          </div>
          <!-- <div class="fr page">
            <div class="sui-pagination pagination-large">
              <ul>
                <li class="prev disabled">
                  <a>«上一页</a>
                </li>
                <li class="active">
                  <a>1</a>
                </li>
                <li>
                  <a>2</a>
                </li>
                <li>
                  <a>3</a>
                </li>
                <li>
                  <a>4</a>
                </li>
                <li>
                  <a>5</a>
                </li>
                <li class="dotted"><span>...</span></li>
                <li class="next">
                  <a>下一页»</a>
                </li>
              </ul>
              <div>
                <span>共10页&nbsp;</span>
                <span>
                  到第
                  <input type="text" class="page-num" />
                  页
                  <button class="page-confirm" onclick="alert(1)">确定</button>
                </span>
              </div>
            </div>
          </div> -->
        </div>
        <!--hotsale-->
        <!-- <div class="clearfix hot-sale">
          <h4 class="title">热卖商品</h4>
          <div class="hot-list">
            <ul class="yui3-g">
              <li class="yui3-u-1-4">
                <div class="list-wrap">
                  <div class="p-img">
                    <img src="/mock/img/like_01.png" />
                  </div>
                  <div class="attr">
                    <em>Apple苹果iPhone 6s (A1699)</em>
                  </div>
                  <div class="price">
                    <strong>
                      <em>¥</em>
                      <i>4088.00</i>
                    </strong>
                  </div>
                  <div class="commit">
                    <i class="command">已有700人评价</i>
                  </div>
                </div>
              </li>
              <li class="yui3-u-1-4">
                <div class="list-wrap">
                  <div class="p-img">
                    <img src="/mock/img/like_03.png" />
                  </div>
                  <div class="attr">
                    <em>金属A面，360°翻转，APP下单省300！</em>
                  </div>
                  <div class="price">
                    <strong>
                      <em>¥</em>
                      <i>4088.00</i>
                    </strong>
                  </div>
                  <div class="commit">
                    <i class="command">已有700人评价</i>
                  </div>
                </div>
              </li>
              <li class="yui3-u-1-4">
                <div class="list-wrap">
                  <div class="p-img">
                    <img src="/mock/img/like_04.png" />
                  </div>
                  <div class="attr">
                    <em>256SSD商务大咖，完爆职场，APP下单立减200</em>
                  </div>
                  <div class="price">
                    <strong>
                      <em>¥</em>
                      <i>4068.00</i>
                    </strong>
                  </div>
                  <div class="commit">
                    <i class="command">已有20人评价</i>
                  </div>
                </div>
              </li>
              <li class="yui3-u-1-4">
                <div class="list-wrap">
                  <div class="p-img">
                    <img src="/mock/img/like_02.png" />
                  </div>
                  <div class="attr">
                    <em>Apple苹果iPhone 6s (A1699)</em>
                  </div>
                  <div class="price">
                    <strong>
                      <em>¥</em>
                      <i>4088.00</i>
                    </strong>
                  </div>
                  <div class="commit">
                    <i class="command">已有700人评价</i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>

    <mainFooter />
  </div>
</template>

<script>
import "@/assets/css/pages-list.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";
import commonPagination from "@/pc/components/pagination";

export default {
  name: "productTopic",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    mainNavigator: mainNavigator,
    commonPagination: commonPagination,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  data() {
    return {
      keywords: undefined,
      type: "",
      query: {
        name: "",
        sort: 1,
      },
      id: 0,
      topic: {},
      topicList: [],
      commentCount: 0,
      commentList: [],
      isAgent: false,
      topicGoods: [],
      topicGoodsA: [],
      integralListTask: [],
      toast1: false,
      minPrice: "",
      maxPrice: "",
      page: 1,
      size: 10,
      sort: "sort_order",
      order: "desc",
      totalCount: 0,
      // article_topicDetail: ""
    };
  },
  mounted() {
    this.pageInit();
  },
  watch: {
    $route() {
      this.pageInit();
    },
  },
  methods: {
    minPriceInput() {
      let aa = Number(this.minPrice || 0);
      let bb = Number(this.maxPrice || 0);
      if (aa < bb) {
        setTimeout(() => {
          this.maxPrice = "";
        });
      }
    },
    maxPriceInput() {
      let aa = Number(this.minPrice || 0);
      let bb = Number(this.maxPrice || 0);
      if (aa > bb) {
        setTimeout(() => {
          this.maxPrice = "";
        });
      }
    },
    pageInit() {
      let options = this.$route.query;
      this.type = options.type;
      // 页面初始化 options为页面跳转所带来的参数
      this.setData({
        id: options.id,
      });
      // if (this.id === "integral") {
      //   this.homeIntegralList();
      // } else
      if (this.id === "newGoods") {
        this.IndexUrl();
      } else {
        this.TopicDetail();
      }
    },
    // homeIntegralList() {
    //   this.toast1 = true;
    //   // uni.showToast({
    //   //   title: "加载中",
    //   //   duration: 2000000,
    //   // });
    //   let that = this;
    //   this.$api.base.homeIntegralList().then((res) => {
    //     this.toast1 = false;
    //     // if (this.toast1) {
    //     //   this.toast1.remove();
    //     //   this.toast1 = null;
    //     // }
    //     this.integralListTask = res || [];
    //     that.integralListTask.forEach((v1, index, arr) => {
    //       v1.dtsGoods &&
    //         v1.dtsGoods.forEach((v2, index2, arr2) => {
    //           arr2[index2].goods.price = Math.min.apply(
    //             null,
    //             v2.products.map((v2) => v2.price)
    //           );
    //           v2.picUrl = v2.goods.picUrl;
    //           v2.name = v2.goods.name;
    //           v2.price = v2.goods.price;
    //           arr2[index2] = v2;
    //         });
    //       arr[index] = v1;
    //     });

    //     that.integralListTask.forEach((v1) => {
    //       if (this.$route.query.integral == v1.id) {
    //         this.topicGoods = v1.dtsGoods;
    //       }
    //     });
    //   });
    // },
    IndexUrl() {
      let that = this;
      this.toast1 = true;
      this.$api.base.IndexUrl().then((res) => {
        this.toast1 = false;
        this.topicGoods = res.newGoodsList;
        that.topicGoods.forEach((v1, index, arr) => {
          v1.goods.price = Math.min.apply(
            null,
            v1.products.map((v1) => v1.price)
          );
          v1.picUrl = v1.goods.picUrl;
          v1.name = v1.goods.name;
          v1.price = v1.goods.price;
          arr[index] = v1;
        });
      });
    },
    TopicDetail(page) {
      let that = this;
      this.toast1 = true;

      this.$api.base
        .TopicDetail({
          id: this.id === "integral" ? this.$route.query.integral : that.id,
          page: page || 1,
          size: that.size,
          sort: that.sort,
          order: that.order,
          keyword: that.keywords,
          minPrice: that.minPrice,
          maxPrice: that.maxPrice,
        })
        .then((res) => {
          this.toast1 = false;
          that.setData({
            topic: res.topic,
            topicGoodsA: res.goods,
            totalCount: res.count,
          });
          that.topicGoodsA.forEach((v1, index, arr) => {
            v1.price = Math.min.apply(
              null,
              v1.dtsGoodsProductList.map((v1) => v1.price)
            );
            arr[index] = v1;
          });
          that.searchQuery(1);

          //WxParse.wxParse('topicDetail', 'html', res.data.topic.content, that)
          // that.article_topicDetail = that.escape2Html(res.data.topic.content);
        });
      this.$api.base
        .TopicRelated({
          id: this.id === "integral" ? this.$route.query.integral : that.id,
        })
        .then(function(res) {
          that.setData({
            topicList: res,
          });
        });
    },
    goBack() {
      uni.navigateTo({
        url: "/",
      });
    },
    searchEvent() {
      let str = this.query.name.trim();
      this.topicGoods = this.topicGoodsA.filter((v1) => {
        return v1.name.includes(str);
      });
    },
    searchQuery(i) {
      // if (i === 1) {
      //   this.query.sort = 1;
      //   this.topicGoodsA = this.topicGoodsA.sort((a, b) => b.sortOrder - a.sortOrder);
      // } else if (i === 2) {
      //   if (this.query.sort === 2) {
      //     this.query.sort = 3;
      //     this.topicGoodsA = this.topicGoodsA.sort((a, b) => a.price - b.price);
      //   } else {
      //     this.query.sort = 2;
      //     this.topicGoodsA = this.topicGoodsA.sort((a, b) => b.price - a.price);
      //   }
      // }
      this.searchEvent();
    },
    jumpGoods(i) {
      let str = "/product/detail/";
      // let str = "/product/catalog/";
      if (i.goods) {
        str += i.goods.id;
      } else {
        str += i.id;
      }
      this.$router.push(str);
    },
    getCommentList() {
      let that = this;
      util
        .request(api.CommentList, {
          valueId: that.id,
          type: 1,
          showType: 0,
          page: 1,
          size: 5,
        })
        .then(function(res) {
          if (res.errno === 0) {
            that.setData({
              commentList: res.data.data,
              commentCount: res.data.count,
            });
          }
        });
    },

    postComment() {
      if (!app.globalData.hasLogin) {
        uni.navigateTo({
          url: "/pages/auth/login/login",
        });
      } else {
        uni.navigateTo({
          url: "/pages/topicCommentPost/topicCommentPost?valueId=" + this.id + "&type=1",
        });
      }
    },
    onSort(sort, order) {
      if (order) {
        this.sort = sort;
      } else {
        this.order = this.order == "asc" ? "desc" : "asc";
      }

      this.page = 1;
      this.sort = sort;
      this.totalPages = 1;
      this.topicGoods = [];
      this.TopicDetail();
    },
    onSearch(keywords) {
      this.keywords = (keywords || this.keywords || "").trim();

      this.sort = "sort_order";
      this.order = "desc";
      this.TopicDetail();

      /* if (this.keywords)
			{
				this.sort = 'sort_order';
				this.order = 'asc';
				this.TopicDetail();
			} else
			{
				uni.showToast({
					title: "请输入搜索关键字",
				});
			} */
    },
  },
};
</script>

<style lang="scss" scoped>
.search-r1 {
  display: flex;
  align-items: center;
}
.search-r1 input {
  border: 0;
  background: none;
  outline: none;
}
.search-r2 {
  border-radius: 19px;
  border: 1px solid rgba(170, 172, 174, 0.3);
  font-size: 16px;
  color: #b5b6b8;
  line-height: 16px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #f4f4f4;
}
.search-r2a {
  height: 28px;
  line-height: 28px;
  width: 80px;
}
.search-r3 {
  display: inline-block;
  width: 12px;
  height: 1px;
  margin: 0 5px;
  background: rgba(170, 172, 174, 0.3);
}
.search-r4 {
  display: flex;
  align-items: center;
  background: rgba(181, 182, 184, 0.3);
  border-radius: 80px;
}
.search-r5 {
  width: 14px;
  height: 14px;
  padding: 7px 8px;
  cursor: pointer;
}
.type-list {
  .active a {
    color: var(--theme-color);
  }
}
.type-wrap {
  display: flex;
  .value {
    flex: 1;
    background: #fff;
  }
}
.part-empty {
  text-align: center;
}
.partA-wrap {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  width: 100%;
  .partA {
    width: 180px;
    list-style: none;
    padding: 0 20px;
    background: #fff;
    margin: 20px 10px;
    cursor: pointer;
  }
  .partAa-a {
    width: 180px;
    height: 180px;
  }
  .partAa-b {
    height: 50px;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .partAa-c {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    padding: 0px 10px;
    background: var(--theme-color);
    border-radius: 4px;
    margin-bottom: 10px;
    display: inline-block;
  }
}
</style>
