<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-29 15:28:09
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <mainHeader />
    <div class="py-container">
      <userMenu />
      <div class="yui3-u-5-6 zc-main">
        <div v-if="$route.query.type == 1">
          <div class="zc-panel">
            <div class="zc-panel-header">
              <h3 class="zc-panel-header-title">我的发票</h3>
            </div>
            <div class="zc-panel-container zc-panel-container-row">
              <div class="tableWrap">
                <div class="tableWrap-1">
                  <div class="tableWrap1">发票信息</div>
                  <div class="tableWrap2">
                    <div v-if="InvoiceList && !InvoiceList.length">暂无发票信息</div>
                    <div class="tableWrap4" v-for="(item, index) in InvoiceList" :key="index">
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">名称</span>
                        <span class="tableWrap3-2">{{ item.name }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">税号</span>
                        <span class="tableWrap3-2">{{ item.taxSn.replace(/(.{5})/g, "$1 ") }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">单位地址</span>
                        <span class="tableWrap3-2">{{ item.depAddress }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">电话</span>
                        <span class="tableWrap3-2">{{ item.phone }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">开户银行</span>
                        <span class="tableWrap3-2">{{ item.openAccountBank }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">银行账户</span>
                        <span class="tableWrap3-2">{{ item.bankAccount.replace(/(.{4})/g, "$1 ") }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tableWrap-1">
                  <div class="tableWrap1">收票信息</div>
                  <div class="tableWrap2">
                    <div v-if="ReceiptList && !ReceiptList.length">暂无收票信息</div>
                    <div class="tableWrap4" v-for="(item, index) in ReceiptList" :key="index">
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">收件姓名</span>
                        <span class="tableWrap3-2">{{ item.receiptName }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">手机号码</span>
                        <span class="tableWrap3-2">{{ item.receiptPhone }}</span>
                      </div>
                      <div class="tableWrap3">
                        <span class="tableWrap3-1">收件地址</span>
                        <span class="tableWrap3-2">{{ item.receiptAddress }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="$route.query.type == 2">
          <div class="zc-panel">
            <div class="zc-panel-header">
              <h3 class="zc-panel-header-title">结算中心</h3>
            </div>
            <div class="title-a" v-if="settleList.one.length">总公司</div>
            <div class="zc-panel-container zc-panel-container-row">
              <div class="tableWrap">
                <div class="tableWrap-1" v-for="(item, index) in settleList.one" :key="index">
                  <div class="tableWrap1">{{ item.name }}</div>
                  <div class="tableWrap2">
                    <div class="tableWrap3">
                      <span class="tableWrap3-1">待开票金额</span>
                      <span class="tableWrap3-2">{{ item.total2 }}元</span>
                    </div>
                    <div class="tableWrap3">
                      <span class="tableWrap3-1">待结算金额</span>
                      <span class="tableWrap3-2">{{ item.total3 }}元</span>
                    </div>
                    <div class="tableWrap3">
                      <span class="tableWrap3-1">已结算金额</span>
                      <span class="tableWrap3-2">{{ item.total4 }}元</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="title-a" v-if="settleList.two.length">分公司</div>
              <div class="tableWrap">
                <div class="tableWrap-1" v-for="(item, index) in settleList.two" :key="index">
                  <div class="tableWrap1">{{ item.name }}</div>
                  <div class="tableWrap2">
                    <div class="tableWrap3">
                      <span class="tableWrap3-1">待开票金额</span>
                      <span class="tableWrap3-2">{{ item.total2 }}元</span>
                    </div>
                    <div class="tableWrap3">
                      <span class="tableWrap3-1">待结算金额</span>
                      <span class="tableWrap3-2">{{ item.total3 }}元</span>
                    </div>
                    <div class="tableWrap3">
                      <span class="tableWrap3-1">已结算金额</span>
                      <span class="tableWrap3-2">{{ item.total4 }}元</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="$route.query.type == 3">
          <div class="zc-panel">
            <div class="zc-panel-header">
              <h3 class="zc-panel-header-title">我的账号管理</h3>
            </div>
            <div class="zc-panel-container zc-panel-container-row">
              <div class="tableWrap">
                <div class="tableWrapB-wrap">
                  <div class="tableWrapB">
                    <div class="tableWrapB-1">账号：</div>
                    <div class="tableWrapB-2" v-if="!userAccount.username1">{{ userAccount.username }}</div>
                    <div class="tableWrapB-2" v-else>
                      <el-input maxlength="100" v-model="userAccount.username"></el-input>
                    </div>
                    <div class="tableWrapB-3">
                      <el-button size="mini" v-if="!userAccount.username1" @click="userAccount.username1 = true">修改账号</el-button>
                      <el-button size="mini" v-else @click="userAccountBtn(1)">保存</el-button>
                    </div>
                  </div>

                  <div class="tableWrapB">
                    <div class="tableWrapB-1">密码：</div>
                    <div class="tableWrapB-2" v-if="!userAccount.password1">*</div>
                    <div class="tableWrapB-2" v-else>
                      <el-input maxlength="30" v-model="userAccount.password"></el-input>
                    </div>
                    <div class="tableWrapB-3">
                      <el-button size="mini" v-if="!userAccount.password1" @click="userAccount.password1 = true">修改密码</el-button>
                      <el-button size="mini" v-else @click="userAccountBtn(2)">保存</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="zc-panel" v-if="$store.state.roleId != 7">
            <div class="zc-panel-header">
              <h3 class="zc-panel-header-title">全部订单</h3>
              <div class="zc-panel-header-operate">
                <a class="icon-more" @click="$router.push({ path: '/user/order/all' })">我的订单</a>
              </div>
            </div>
            <div class="zc-panel-container">
              <template v-if="list.orders && list.orders.length">
                <table class="zc-table-title">
                  <colgroup>
                    <col style="width:auto;" />
                    <col style="width:80px;" />
                    <col style="width:80px" />
                    <col style="width:100px" />
                    <col style="width:120px" />
                    <col style="width:120px" />
                    <!-- <col style="width:140px" /> -->
                  </colgroup>
                  <thead>
                    <tr>
                      <th>宝贝</th>
                      <th>单价</th>
                      <th>数量</th>
                      <th>收货人</th>
                      <th>实付款</th>
                      <th>交易状态</th>
                      <!-- <th>订单操作</th> -->
                    </tr>
                  </thead>
                </table>
                <template v-for="(order, i) in list.orders">
                  <div class="zc-table-item" :key="i">
                    <div class="zc-table-spliter">
                      <span>订单编号：{{ order.orderSn }}</span>
                      <span>{{ order.addTime }}</span>
                      <span>{{ order.department.pid == 0 ? "总" : "子" }}公司：{{ order.department.name }}</span>
                      <span v-if="order.user">账号：{{ order.user.username }}</span>
                    </div>
                    <table class="zc-table-content">
                      <colgroup>
                        <col style="width:auto;" />
                        <col style="width:80px;" />
                        <col style="width:80px" />
                        <col style="width:100px" />
                        <col style="width:120px" />
                        <col style="width:120px" />
                        <!-- <col style="width:140px" /> -->
                      </colgroup>
                      <tbody>
                        <tr v-for="(goods, m) in order.goodsList" :key="m" :temp="(span = order.goodsList.length)" :class="{ 'zc-tr-disabled': !goods.isExist }">
                          <template>
                            <td>
                              <div class="zc-figure-text">
                                <div class="zc-figure-image">
                                  <img :src="goods.picUrl" />
                                </div>
                                <h3>
                                  <strong>
                                    <a @click="$router.push({ path: `/product/detail/${goods.goodsId}` })" v-if="goods.isExist">{{ goods.goodsName }}</a>
                                    <span v-else>{{ goods.goodsName }}</span>
                                  </strong>
                                  <small>规格：{{ goods.specifications.join(" ") }}</small>
                                  <small v-if="goods.refundStatus || goods.refundStatus == 0">售后状态：{{ refundStatus1[goods.refundStatus] }}</small>
                                </h3>
                              </div>
                            </td>
                            <td>
                              <ul>
                                <!-- <li class="zc-text-delete">¥{{goods.price}}</li> -->
                                <li class="color1">¥{{ goods.price }}</li>
                              </ul>
                            </td>
                            <td class="color1">x{{ goods.number }}</td>
                          </template>
                          <template v-if="m == 0">
                            <td :rowspan="span">
                              <span class="color1">{{ order.consignee }}</span>
                              <!-- <a style="margin:5px 0" class="button-solid button-yellow" v-if="order.handleOption.rebuy && goods.isExist" @click="onOrderAgain(goods.goodsId)">再次购买</a> -->

                              <!-- <span v-if="!(order.handleOption.rebuy && goods.isExist) && !goods.refundId">-</span> -->
                            </td>
                            <td :rowspan="span">
                              <ul>
                                <li class="color1">¥{{ order.actualPrice }}</li>
                                <!-- <li>（含运费：￥{{ order.freightPrice }}）</li> -->
                              </ul>
                            </td>
                            <td :rowspan="span">
                              <ul>
                                <li class="color1">{{ order.orderStatusText }}</li>
                              </ul>
                            </td>
                            <!-- <td :rowspan="span" class="zc-table-content-operate">
                            </td> -->
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </template>
              <p class="zc-panel-empty" v-else>
                <img :src="require('@/assets/images/n2.png')" />
                <strong>您还没有订单</strong>
                <small><a @click="$router.push({ path: '/home' })">继续逛逛吧</a></small>
              </p>
            </div>
          </div>

          <div class="zc-panel" v-hasPermi="['user:order:collect']">
            <div class="zc-panel-header">
              <h3 class="zc-panel-header-title">商品收藏</h3>
              <div class="zc-panel-header-operate">
                <a class="icon-more" @click="$router.push({ path: '/user/favorite' })">我的收藏</a>
              </div>
            </div>
            <div class="zc-panel-container zc-panel-container-row">
              <template v-if="collectList && collectList.length">
                <template v-for="(product, index) in collectList">
                  <productCard class="yui3-u-1-5" :url="`/product/detail/${product.valueId}`" :thumb="product.picUrl" :title="product.name" :price="product.retailPrice" :disable="!product.isExist ? '商品失效' : false" :key="index" />
                </template>
              </template>
              <p class="zc-panel-empty" v-else>
                <img :src="require('@/assets/images/n2.png')" />
                <strong>您还没有收藏</strong>
                <small><a @click="$router.push({ path: '/home' })">继续逛逛吧</a></small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mainFooter />

    <el-dialog title="退款详情" :visible.sync="dialog.refund" :close-on-click-modal="false" :close-on-press-escape="false" width="400px" class="zc-dialog  zc-dialog-detail">
      <el-form label-position="left" label-width="100px" size="mini">
        <el-form-item label="退单编号">
          <span>{{ form.refund.refundSn }}</span>
        </el-form-item>
        <el-form-item label="申请时间">
          <span>{{ form.refund.addTime }}</span>
        </el-form-item>
        <el-form-item label="退款状态">
          <span>{{ form.refund.status == 0 ? "等待审批" : form.refund.status == 2 ? "审批拒绝" : "审批通过" }}</span>
        </el-form-item>
        <el-form-item label="拒绝原因" v-if="form.refund.status == 2">
          <span>{{ form.refund.approveMsg }}</span>
        </el-form-item>
        <el-form-item label="申请总额">
          <span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
          <span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
        </el-form-item>
        <el-form-item label="审批总额" v-if="form.refund.status == 1 || form.refund.status == 3">
          <span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
          <span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
        </el-form-item>
        <el-form-item label="原订单编号">
          <span>{{ form.refund.orderSn }}</span>
        </el-form-item>
        <el-form-item label="收货人">
          <span>{{ form.refund.consignee }}</span>
        </el-form-item>
        <el-form-item label="联系方式">
          <span>{{ form.refund.mobile }}</span>
        </el-form-item>
      </el-form>
      <img style="position:absolute;right:10px;bottom:30px;" :src="form.refund.status == 0 ? require('../../../assets/images/wait.png') : form.refund.status == 2 ? require('../../../assets/images/reject.png') : require('../../../assets/images/pass.png')" />
    </el-dialog>
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import userMenu from "@/pc/components/userMenu";
import productCard from "@/pc/components/productCard";
import commonSlider from "@/pc/components/slider";
import { Encrypt } from "@/assets/js/secret.js";
import { encrypt, decrypt } from "@/assets/js/jsencrypt";

export default {
  name: "userIndex",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    userMenu: userMenu,
    commonSlider: commonSlider,
    productCard: productCard,
  },
  data() {
    return {
      refundStatus1: {
        0: "待供应商操作",
        2: "审批拒绝",
        3: "审批通过",
      },
      userAccount: {
        username: "",
        password: "",
        username1: false,
        password1: false,
      },
      type: 0,
      collectList: [],
      page: 1,
      size: 10,
      totalPages: 1,
      list: {
        orders: [],
      },
      dialog: {
        refund: false,
      },
      form: {
        refund: {},
      },
      InvoiceList: [],
      ReceiptList: [],
      settleList: {
        one: [],
        two: [],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  created() {
    this.getOrderList(1, 2);
    this.getCollectList();
  },
  mounted() {
    this.getInvoice();
    this.getReceipt();
    this.getSettle();

    this.userAccount = {
      username: this.user.username,
      password: "", // localStorage.password,
      username1: false,
      password1: false,
    };
  },
  methods: {
    userAccountBtn(i) {
      if (!this.userAccount?.username) {
        this.$message.warning("请输入账号");
        return;
      }
      if (!this.userAccount?.password) {
        this.$message.warning("请输入密码");
        return;
      }
      if (i == 1) {
        this.userAccount.username1 = false;
      } else if (i == 2) {
        this.userAccount.password1 = false;
      }
      this.updateInfo();
    },
    updateInfo() {
      if(!(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()\-_=+{};:,<.>|'/"?]).{6,30}$/.test(this.userAccount.password))){
        this.$message.warning("新密码要求是大写字母、小写字母、数字和特殊字符的组合，最短不少于6位，最长不超过30位。");
        return;
      }
      this.$api.base
        .updateInfo({
          username: this.userAccount.username.trim(),
          password: Encrypt(this.userAccount.password.trim()),
          // password: this.userAccount.password.trim(),
        })
        .then((res) => {
          // localStorage.password = this.userAccount.password;
          this.$message.success("操作成功");

          this.$store.commit("setUser", res);
          localStorage.userInfo = JSON.stringify(res);
        })
        .catch(() => {
          // this.userAccount.password = localStorage.password;
          this.userAccount.username = this.user.username;
        });
    },
    getSettle() {
      this.$api.base.getSettle().then((res) => {
        res.forEach((v1) => {
          if (v1.pid == 0) {
            this.settleList.one.push(v1);
          } else {
            this.settleList.two.push(v1);
          }
        });
      });
    },
    getInvoice() {
      this.$api.base.getInvoice().then((res) => {
        this.InvoiceList = res;
      });
    },
    getReceipt() {
      this.$api.base.getReceipt().then((res) => {
        this.ReceiptList = res;
      });
    },
    getCollectList() {
      let that = this;
      this.$api.base
        .CollectList({
          type: that.type,
          page: that.page,
          size: that.size,
        })
        .then(function(res) {
          that.setData({
            collectList: that.collectList.concat(res.collectList),
            totalPages: res.totalPages,
          });
        });
    },
    getOrderList(page, size) {
      this.$api.base
        .OrderList({
          departmentId: sessionStorage.departmentId,
          page,
          size,
        })
        .then((response) => {
          this.list.orders = response.data;
        });
    },
    onOrderPay(order) {
      this.$router.push({
        path: "/order/pay",
        query: {
          orderId: order.id,
          actualPrice: order.actualPrice,
          orderSn: order.orderSn,
        },
      });
    },
    onOrderCancel(order) {
      this.$api.base.OrderCancel({ orderId: order.id }).then((response) => {
        this.getOrderList(1, 2);
      });
    },
    onOrderDelete(order) {
      this.$api.base.OrderDelete({ orderId: order.id }).then((response) => {
        this.getOrderList(1, 2);
      });
    },
    onOrderConfirm(order) {
      this.$api.base.OrderConfirm({ orderId: order.id }).then((response) => {
        this.getOrderList(1, 2);
      });
    },
    onOrderAgain(id) {
      this.$router.push({ path: "/product/detail/" + id });
    },
    onRefundDetail(id) {
      this.$api.base.RefundOrderQuery({ refundId: id }).then((response) => {
        this.form.refund = Object.assign(response.refundTrace, { consignee: response.orderInfo.consignee, mobile: response.orderInfo.mobile });
        this.dialog.refund = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tableWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .tableWrap-1 {
    border-radius: 3px;
    border: 1px solid #c1c1c1;
    width: 48%;
    margin-bottom: 30px;
    .tableWrap1 {
      padding: 8px 40px;
      background: #f2f3f6;
      border-radius: 3px 3px 0px 0px;
      font-size: 14px;
      font-weight: 400;
      color: #b5b6b8;
      line-height: 20px;
    }
    .tableWrap2 {
      padding: 18px 40px;
    }
    .tableWrap3 {
      display: flex;
      margin-bottom: 14px;
      .tableWrap3-1 {
        font-size: 14px;
        font-weight: 400;
        color: #b5b6b8;
        line-height: 20px;
        width: 70px;
        margin-right: 20px;
        height: 20px;
        text-align: justify;
        text-align-last: justify;
      }
      .tableWrap3-2 {
        flex: 1;
        width: 0;
        font-size: 14px;
        font-weight: 400;
        color: #5e6063;
        line-height: 20px;
      }
    }
    .tableWrap4 {
      width: 100%;
      padding-bottom: 30px;
      padding-top: 40px;
      border-bottom: 1px solid rgba(193, 193, 193, 0.3);
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  .tableWrapB-wrap {
    width: 100%;
    .tableWrapB {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #d7d7d7;
      width: 100%;
      font-size: 16px;
    }
    .tableWrapB-1 {
      width: 50px;
      padding: 15px 0;
    }
    .tableWrapB-2 {
      flex: 1;
      width: 0;
      padding-right: 20px;
    }
  }
}
.title-a {
  font-size: 16px;
  padding-bottom: 10px;
  color: #898b8d;
}
</style>
