<template>
  <div>
    <mainHeader />
    <div class="cart py-container">
      <!--主内容-->
      <div class="paysuccess">
        <div class="success">
          <h3>
            <img src="/mock/img/_/right.png" width="48" height="48" />
            恭喜您，支付成功啦！
          </h3>
          <div>
            <p>支付方式：支付宝</p>
            <p>支付金额：￥{{ actualPrice }}</p>
            <p class="button">
              <a @click="$router.replace('/user/order/all')" class="sui-btn btn-danger btn-xlarge">查看订单</a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a @click="$router.replace('/')" class="sui-btn btn-xlarge btn-goshop">继续购物</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import "@/assets/css/pages-paysuccess.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

export default {
  name: "orderPaySuccess",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter
  },
  data() {
    return {
      actualPrice: 0
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.actualPrice = this.$route.query.actualPrice;
  },
  methods: {}
};
</script>

<style scoped>
.success {
  text-align: center;
  font-size: 16px;
}
</style>
