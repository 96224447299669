/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-09-22 14:50:06
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\router.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

import homeIndex from "./pages/home/index";
import userLogin from "./pages/user/login";
import userIndex from "./pages/user/index";
import userCart from "./pages/user/cart";
import userOrder from "./pages/user/order";
import userOrderDetail from "./pages/user/orderDetail";
import userOrderRefund from "./pages/user/orderRefund";
import userFavorite from "./pages/user/favorite";
import userAddress from "./pages/user/address";
import productTopic from "./pages/product/topic";
import productCatalog from "./pages/product/catalog";
import productNotice from "./pages/product/notice";
import productSearch from "./pages/product/search";
import productDetail from "./pages/product/detail";
import orderConfirm from "./pages/order/confirm";
import orderPay from "./pages/order/pay";
import orderPaySuccess from "./pages/order/paySuccess";
import orderPayFail from "./pages/order/payFail";
import page from "./pages/page";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const title = "泽超集采商城";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    //首页
    path: "/home",
    name: "homeIndex",
    component: homeIndex,
    meta: {
      title: title,
    },
  },
  {
    //用户登录
    path: "/user/login",
    name: "userLogin",
    component: userLogin,
    meta: {
      title: `${title} | 用户登录注册`,
    },
  },
  {
    //我的账户
    path: "/user/index",
    name: "userIndex",
    component: userIndex,
    meta: {
      title: `${title} | 用户中心`,
      login: true,
    },
  },
  {
    //购物车
    path: "/user/cart",
    name: "userCart",
    component: userCart,
    meta: {
      title: `${title} | 购物车`,
      login: true,
    },
  },
  {
    //我的订单
    path: "/user/order/detail/:id",
    name: "userOrderDetail",
    component: userOrderDetail,
    meta: {
      title: `${title} | 订单详情`,
      login: true,
    },
  },
  {
    //退款页
    path: "/user/order/refund/:id",
    name: "userOrderRefund",
    component: userOrderRefund,
    meta: {
      title: `${title} | 订单详情`,
      login: true,
    },
  },
  {
    //我的订单
    path: "/user/order/:mode",
    name: "userOrder",
    component: userOrder,
    meta: {
      title: `${title} | 我的订单`,
      login: true,
    },
  },
  {
    //我的收藏
    path: "/user/favorite",
    name: "userFavorite",
    component: userFavorite,
    meta: {
      title: `${title} | 我的收藏`,
      login: true,
    },
  },
  {
    //我的地址
    path: "/user/address",
    name: "userAddress",
    component: userAddress,
    meta: {
      title: `${title} | 我的地址`,
      login: true,
    },
  },
  {
    // 专题
    path: "/product/topic",
    name: "productTopic",
    component: productTopic,
    meta: {
      title: `${title} | 专题`,
    },
  },
  {
    // 公告
    path: "/product/notice",
    name: "productNotice",
    component: productNotice,
    meta: {
      title: `${title} | 公告`,
    },
  },
  {
    //商品分类
    path: "/product/catalog",
    name: "productCatalog",
    component: productCatalog,
    meta: {
      title: `${title} | 商品分类`,
    },
  },
  {
    //商品分类
    path: "/product/catalog/:id",
    name: "productCatalog",
    component: productCatalog,
    meta: {
      title: `${title} | 商品分类`,
    },
  },
  {
    //商品搜索
    path: "/product/search/",
    name: "productSearch",
    component: productSearch,
    meta: {
      title: `商品搜索`,
    },
  },
  {
    //商品搜索
    path: "/product/search/:keywords",
    name: "productSearch",
    component: productSearch,
    meta: {
      title: `${title} | 商品搜索`,
    },
  },
  {
    //商品详情
    path: "/product/detail/:id",
    name: "productDetail",
    component: productDetail,
    meta: {
      title: `${title} | 商品详情`,
    },
  },
  {
    //订单确认
    path: "/order/confirm",
    name: "orderConfirm",
    component: orderConfirm,
    meta: {
      title: `${title} | 订单确认`,
      login: true,
    },
  },
  {
    //订单提交成功
    path: "/order/pay",
    name: "orderPay",
    component: orderPay,
    meta: {
      title: `${title} | 订单提交成功`,
      login: true,
    },
  },
  {
    //支付成功
    path: "/order/paySuccess",
    name: "orderPaySuccess",
    component: orderPaySuccess,
    meta: {
      title: `${title} | 支付成功`,
      login: true,
    },
  },
  {
    //支付失败
    path: "/order/payFail",
    name: "orderPayFail",
    component: orderPayFail,
    meta: {
      title: `${title} | 支付失败`,
      login: true,
    },
  },
  {
    //支付失败
    path: "/page/:mode",
    name: "page",
    component: page,
    meta: {
      title: `${title} | 信息提示`,
    },
  },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  console.log(to);
  if (to.meta && to.meta.login && !store.getters.isLogin) {
    next({ path: "/user/login" });
    //next();
  } else {
    next();
  }

  scrollTo(0, 0);
});

export default router;
