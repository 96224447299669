<template>
  <div>
    <div class="modalWrap" v-if="pageLoading">
      <div class="modalWrap1">
        <i class="el-icon-loading" style="font-size: 60px"></i>
        <p>页面加载中</p>
      </div>
    </div>
    <mainHeader />
    <!--列表-->
    <mainNavigator complex />
    <div class="rightBar" :style="rightBarCss">
      <div class="rightBar1">
        <div v-if="!user" @click="$router.push({ path: '/user/login' })">
          <img src="../../../assets/images/portrait.png" alt="" />
          <p>去登录</p>
        </div>
        <div v-if="user" @click="$router.push('/user/index')">
          <img src="../../../assets/images/p1.png" alt="" />
          <p>我的账户</p>
        </div>
        <div v-if="user" @click="$router.push('/user/favorite')" v-hasPermi="['user:order:collect']">
          <img src="../../../assets/images/p4.png" alt="" />
          <p>我的收藏</p>
        </div>
        <div class="rightBar2">
          <img src="../../../assets/images/p2.png" alt="" />
          <p>客服电话</p>
          <div class="rightBar3">
            <div>欢迎拨打负责人热线</div>
            <div class="rightBar4">15371016241</div>
            <div class="rightBar6">欢迎拨打客服热线</div>
            <div class="rightBar7">400-008-6770</div>
            <div class="rightBar5">服务时间 9:00-17:00</div>
          </div>
        </div>
        <div @click="goToTopBtn">
          <img src="../../../assets/images/p5.png" alt="" />
          <p>回到顶部</p>
        </div>
      </div>
    </div>
    <el-carousel height="610px">
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <div style="width: 100%; height: 100%;overflow: hidden;position: relative;">
          <img style="width: 1920px;height: 610px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);max-width: 1920px;" :src="item.url" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <!--推荐-->
    <div id="scrollStop3">
      <div v-for="(item1, index1) in topic1" :key="index1 + '1'">
        <div class="py-container">
          <div class="oneList">
            <div class="oneList1">
              <div class="today" @click="jumpTopic(item1, '每日精选')">
                <img :src="item1.picUrl" />
              </div>
              <ul class="oneList2">
                <template v-for="(item, index) in item1.topicGoods">
                  <li class="unit1" :key="index" v-if="index < 8">
                    <div @click="jumpGoods(item)">
                      <img class="unit2" :src="item.picUrl" alt="" />
                      <div class="unit3">
                        <p>{{ item.name }}</p>
                        <span>
                          ¥
                          <b>{{ item.price }}</b>
                        </span>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="like">
      <div class="py-container">
        <div class="title">
          <h3 class="fl">新品推荐</h3>
          <b class="border"></b>
        </div>
        <div class="bd">
          <ul class="part1-a">
            <li class="unit1" v-for="(item, index) in newGoods" :key="index">
              <div :vars="(it = item.goods)">
                <div @click="jumpGoods(item)">
                  <a>
                    <img class="unit2" :src="it.picUrl" alt="" />
                  </a>
                  <div class="unit3">
                    <p>{{ it.name }}</p>
                    <span>
                      ¥
                      <b>{{ it.price }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <div id="scrollStop1" style="margin: 20px 0;">
      <div class="py-container py-container1" style="background-color:rgba(0,0,0,0)">
        <!-- <div class="title">
          <h3 class="fl">泽超心选</h3>
          <b class="border"></b>
        </div> -->
        <div class="integral1">
          <div class="integral2" v-for="(item, index) in integralListTask" @click="jumpTopic({ id: 'integral' }, '泽超心选', item)" :key="index">
            <img class="integral3" :src="item.picUrl" alt="" />
            <div class="integral4">{{ item.title }}</div>
            <div class="integral5">{{ item.subtitle }}</div>
          </div>
        </div>
        <!-- 
        <div class="bd">
          <ul class="part1-a">
            <li class="unit1" v-for="(item1, index1) in item.dtsGoods" :key="index1">
              <div :vars="(it1 = item1.goods)">
                <div @click="jumpGoods(item1)">
                  <a>
                    <img class="unit2" :src="it1.picUrl" alt="" />
                  </a>
                  <div class="unit3">
                    <p>{{ it1.name }}</p>
                    <span>
                      ¥
                      <b>{{ it1.price }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="like" id="scrollStop2">
      <div class="py-container py-container1">
        <div class="title">
          <h3 class="fl">品牌闪购</h3>
          <b class="border"></b>
        </div>
        <div class="topicList">
          <li class="yui3-u-5-24" v-for="(item, index) in topic2" :key="index + '2'" @click="jumpTopic(item, '品牌闪购')">
            <img :src="item.picUrl" />
            <p>{{ item.title }}</p>
          </li>
        </div>
      </div>
    </div>
    <div class="like">
      <div class="py-container">
        <el-carousel height="240px">
          <el-carousel-item>
            <img src="/imgList/jiao1.jpg" @click="jumpTopic({ id: 'newGoods' }, '新品首发')" style="width: 100%;border-radius: 8px" alt="" />
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img src="../../../assets/images/jiao2.jpg" @click="jumpTopic({ id: 'newGoods' }, '新品首发')" style="width: 100%;border-radius: 8px" alt="" />
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <div class="like" v-if="hotGoods && hotGoods.length">
      <div class="py-container py-container1">
        <div class="title">
          <h3 class="fl">人气推荐</h3>
          <b class="border"></b>
        </div>
        <div class="bd">
          <ul class="part1-a">
            <li class="unit1" v-for="(item, index) in hotGoods" :key="index">
              <div :vars="(it = item.goods)">
                <div @click="jumpGoods(item)">
                  <a>
                    <img class="unit2" :src="it.picUrl" alt="" />
                  </a>
                  <div class="unit3">
                    <p>{{ it.name }}</p>
                    <span>
                      ¥
                      <b>{{ it.price }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <mainFooter />
    <!-- <mainDock /> -->
  </div>
</template>

<script>
import "@/assets/css/pages-JD-index.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";
import mainDock from "@/pc/components/dock";
import slider from "@/pc/components/slider";

export default {
  name: "homeIndex",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    mainNavigator: mainNavigator,
    mainDock: mainDock,
    slider,
  },
  data() {
    return {
      newGoods: [],
      hotGoods: [],
      topics: [],
      topic1: [],
      topic2: [],
      integralListTask: [],
      banner: [],
      rightBarCss: "",
      pageLoading: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.IndexUrl();
    this.homeTopicList();
    this.homeIntegralList();
    // if (sessionStorage.navclick) {
    //   setTimeout(() => {
    //     this.navclick(sessionStorage.navclick);
    //   }, 500);
    // }
    this.rightBarSet();
  },
  methods: {
    rightBarSet() {
      // let aa = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      // if (aa > 350) {
      //   this.rightBarCss = `right: 0px`;
      // } else {
      //   this.rightBarCss = `right: ${window.innerWidth - 1200 > 0 ? (window.innerWidth - 1200) / 2 : 0}px`;
      // }
      let num1 = (window.innerWidth - 1200) / 2 - 106;
      this.rightBarCss = `right: ${num1 > 0 ? num1 : 0}px`;
    },
    goToTopBtn() {
      document.documentElement.scrollTop = 0;
    },
    // navclick(i) {
    //   if (i == 3) {
    //     let div1 = document.querySelector("#scrollStop1");
    //     if (div1) {
    //       document.documentElement.scrollTop = document.documentElement.scrollTop + div1.getBoundingClientRect().top;
    //     }
    //   } else if (i == 4) {
    //     let div2 = document.querySelector("#scrollStop2");
    //     if (div2) {
    //       document.documentElement.scrollTop = document.documentElement.scrollTop + div2.getBoundingClientRect().top;
    //     }
    //   } else if (i == 5) {
    //     let div2 = document.querySelector("#scrollStop3");
    //     if (div2) {
    //       document.documentElement.scrollTop = document.documentElement.scrollTop + div2.getBoundingClientRect().top;
    //     }
    //   }
    //   setTimeout(() => {
    //     sessionStorage.navclick = "";
    //   }, 100);
    // },
    todayList(id, num) {
      this.$api.base
        .TopicDetail({
          id: id,
          sort: "sort_order",
          order: "desc",
          keyword: "",
          page: 1,
          size: 8,
          minPrice: "",
          maxPrice: "",
        })
        .then((res) => {
          let fixarr = res.goods.map((v1) => {
            v1.price = Math.min.apply(
              null,
              v1.dtsGoodsProductList.map((v1) => v1.price)
            );
            return v1;
          });
          this.topic1[num].topicGoods = fixarr;
          this.topic1 = JSON.parse(JSON.stringify(this.topic1));
          let num1 = num + 1;
          if (num1 < this.topic1.length) {
            this.todayList(this.topic1[num1].id, num1);
          }
        });
    },
    showNotify(i) {
      if (i.content) {
        uni.showModal({
          title: "公告",
          content: i.content,
        });
      }
    },
    homeIntegralList() {
      let that = this;
      this.$api.base.homeIntegralList().then((res) => {
        this.integralListTask = res || [];
        that.integralListTask.forEach((v1, index, arr) => {
          v1.dtsGoods &&
            v1.dtsGoods.forEach((v2, index2, arr2) => {
              arr2[index2].goods.price = Math.min.apply(
                null,
                v2.products.map((v1) => v1.price)
              );
            });
          arr[index] = v1;
        });
      });
    },
    IndexUrl() {
      this.pageLoading = true;
      let that = this;
      this.$api.base.IndexUrl().then((res) => {
        this.pageLoading = false;
        this.newGoods = res.newGoodsList;
        this.hotGoods = res.hotGoodsList;
        this.banner = res.banner;

        that.newGoods.forEach((v1, index, arr) => {
          v1.goods.price = Math.min.apply(
            null,
            v1.products.map((v1) => v1.price)
          );
          arr[index] = v1;
        });
        that.hotGoods.forEach((v1, index, arr) => {
          v1.goods.price = Math.min.apply(
            null,
            v1.products.map((v1) => v1.price)
          );
          arr[index] = v1;
        });
      });
    },
    homeTopicList() {
      let that = this;
      this.$api.base.homeTopicList().then((res) => {
        this.topics = res || [];
        that.topic1 = that.topics.filter((v1) => {
          return v1.location == "1";
        });
        that.topic1.sort((a, b) => {
          return b.sortOrder - a.sortOrder;
        });
        if (that.topic1.length) {
          that.todayList(that.topic1[0].id, 0);
        }
        that.topic2 = that.topics
          .filter((v1) => {
            return v1.location == "2";
          })
          .sort((a, b) => {
            return b.sortOrder - a.sortOrder;
          });
      });
    },
    GoodsCount() {
      this.$api.base.GoodsCount().then((res) => {
        this.goodsCount = res.goodsCount;
      });
    },
    jumpTopic(i, j, k) {
      if (i.thirdPartyUrl) {
        window.open(i.thirdPartyUrl);
        return;
      }
      let str = "/product/topic/";
      this.$router.push({
        path: str,
        query: {
          id: i ? i.id : j,
          type: j,
          integral: k ? k.id : "",
        },
      });
    },
    jumpGoods(i) {
      let str = "/product/detail/";
      // let str = "/product/catalog/";
      if (i.goods) {
        str += i.goods.id;
      } else {
        str += i.id;
      }
      this.$router.push(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.like {
  border: 0;
}
.title {
  padding: 10px 20px 0;
}
.py-container1 {
  background: #fff;
  border-radius: 10px !important;
  overflow: hidden;
}
.part1-a {
  display: flex;
  flex-wrap: wrap;
}
.part1-a,
.oneList1 {
  background-color: #fff;
  .unit1 {
    width: 180px;
    list-style: none;
    padding: 0 20px;
    background: #fff;
    border-radius: 8px;
    margin: 0 10px;
  }
  .unit2 {
    width: 180px;
    height: 180px;
    margin: 10px auto 10px;
    border-radius: 5px;
  }
  .unit3 {
    span {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      padding: 0px 10px;
      background: var(--theme-color);
      border-radius: 4px;
      margin-bottom: 10px;
      display: inline-block;
    }
    p {
      height: 50px;
      margin-bottom: 10px;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.news-list {
  height: 300px;
  overflow: hidden auto;
}
.oneList1 {
  background: none;
}
.topicList {
  li {
    width: 220px;
    margin: 10px;
    img {
      width: 210px;
      height: 210px;
      border-radius: 5px;
    }
    p {
      font-size: 16px;
      background: #fff;
      text-align: center;
      margin-top: 8px;
    }
  }
}
.oneList {
  overflow: auto hidden;
  .oneList1 {
    margin: 20px 0 0;
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;
    overflow: hidden;
  }
  .oneList2 {
    flex: 1;
    width: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .unit1 {
    width: 230px;
    margin: 4px;
    padding: 0;
    cursor: pointer;
  }
  .unit2 {
    margin: 0;
    width: 230px;
    height: 230px;
    border-radius: 8px 8px 0 0;
  }
  .unit3 {
    padding: 0 8px;
    p {
      height: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .today {
    height: 648px;
    width: 240px;
    padding: 0;
    margin: 4px 0;
    margin-right: 4px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
}
.topBtn1 {
  background: #fff;
  .topBtn2 {
    width: 50%;
    display: inline-block;
    padding: 17px 0;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
}

.integral1 {
  // background: url("../../../assets/img/p2.jpg") no-repeat;
  background: url("/imgList/p2.jpg") no-repeat;
  background-size: 100%;
  border-radius: 8px;
  padding-top: 135px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .integral2 {
    padding: 10px;
    display: inline-block;
    background: #ffecba;
    border-radius: 8px;
    margin: 5px;
  }
  .integral3 {
    width: 206px;
    height: 210px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .integral4 {
    text-align: center;
    font-size: 18px;
    color: #fff;
    line-height: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    background: url("../../../assets/images/p3.png") no-repeat;
    background-size: 100% 100%;
    width: 166px;
    padding: 0 20px;
  }
  .integral5 {
    width: 206px;
    margin-top: 7px;
    text-align: center;
    font-size: 18px;
    color: #f73900;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
}

// .el-carousel--horizontal {
//   border-radius: 8px;
// }

// .el-carousel__item {
//   border-radius: 8px;
// }
::v-deep .el-carousel__arrow {
  display: none !important;
}
.rightBar {
  position: fixed;
  z-index: 400;
  top: 244px;
  right: 0;
  width: 86px;
  background: #fff;
  border-radius: 8px;
  padding-top: 24px;
  transition: all 0.3s linear;
  .rightBar1 {
    text-align: center;
    font-size: 0;
    div {
      cursor: pointer;
    }
    img {
      width: 32px;
      height: 32px;
    }
    p {
      padding: 4px 0 24px;
      margin: 0;
      font-size: 12px;
      color: #898b8d;
      line-height: 17px;
    }
    .rightBar2 {
      position: relative;
      .rightBar3 {
        display: none;
        position: absolute;
        right: 80px;
        top: 0;
        width: 200px;
        background: #fff;
        color: #000;
        font-size: 16px;
        padding: 20px;
        border-radius: 8px;
        text-align: left;
        line-height: 30px;
      }
      .rightBar6 {
        font-size: 14px;
      }
      .rightBar4 {
        font-size: 20px;
        font-weight: bold;
      }
      .rightBar7 {
        font-size: 20px;
      }
      .rightBar5 {
        font-size: 14px;
        color: #999;
      }
      &:hover .rightBar3 {
        display: block;
      }
    }
  }
}
.modalWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalWrap1 {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
</style>
