<template>
  <div>
    <mainHeader />
    <div class="cart py-container">
      <!--主内容-->
      <div class="payfail">
        <div class="fail">
          <h3>
            <img src="/mock/img/_/fail.png" width="48" height="48" />
            支付失败，请稍后再试
          </h3>
          <div>
            <p>
              您可以先去
              <a @click="$router.replace('/')" style="color: var(--theme-color)">首页</a>
              逛逛
            </p>
            <p class="button"><a @click="$router.go(-1)" class="sui-btn btn-xlarge btn-danger">重新支付</a></p>
          </div>
        </div>
      </div>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import "@/assets/css/pages-payfail.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

export default {
  name: "orderPayFail",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
  },
  data() {
    return {
      actualPrice: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.actualPrice = this.$route.query.actualPrice;
  },
  methods: {},
};
</script>

<style scoped>
.fail {
  text-align: center;
  font-size: 16px;
}
</style>
