<template>
  <div>
    <mainHeader />
    <div class="zc-login">
      <div class="zc-login-panel">
        <div class="zc-login-form">
          <div class="formTabs" v-if="formType != 3">
            <!-- <div class="formTabs1" :class="formType == 1 && 'formTabs2'" @click="formType = 1">
              <span>微信授权登录</span>
            </div>
            <div class="formTabs1" :class="formType == 2 && 'formTabs2'" @click="formType = 2">
              <span>手机号码登录</span>
            </div> -->
            <div class="formTabs1">
              <span>账户登录</span>
            </div>
            <div class="formTabs1"></div>
          </div>
          <!-- <div class="formTabs" v-if="formType == 3">
            <div class="formTabs1 formTabs2">
              <span>绑定手机号码</span>
            </div>
          </div>
          <div v-show="formType == 1">
            <div id="wechat-login"></div>
          </div> -->
          <div v-if="formType != 1">
            <div class="zc-login-item">
              <img class="zc-login-icon" :src="require('@/assets/images/icon_account.png')" />
              <input type="text" v-model="userForm.phone" placeholder="请输入账号" />
            </div>
            <div class="zc-login-item">
              <img class="zc-login-icon" :src="require('@/assets/images/icon_password.png')" />
              <input type="password" v-model="userForm.code" autocomplete="off" placeholder="请输入密码" />
              <!-- <span class="zc-login-code" @click="getMsgCode">{{ msgCodeTxt }}</span> -->
            </div>
            <a class="zc-login-button" @click="AuthLoginByAccount">登&nbsp;&nbsp;录</a>
          </div>
          <!-- 
          <div style="padding-top: 20px">
            <el-checkbox v-model="checked"></el-checkbox>
            <span style="padding-left: 10px">
              未注册用户扫码后自动登录，注册即代表同意
              <span style="color: var(--theme-color);cursor: pointer;" @click="showRead('1')">《用户须知》</span>
              和
              <span style="color: var(--theme-color);cursor: pointer;" @click="showRead('2')">《隐私协议》</span>
            </span>
          </div> -->
        </div>
      </div>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import { Encrypt } from "@/assets/js/secret.js";
import { encrypt, decrypt } from "@/assets/js/jsencrypt";

export default {
  name: "userLogin",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
  },
  data() {
    return {
      userForm: {
        phone: "",
      },
      getMsgDoor: false,
      msgCodeTxt: "获取验证码",
      formType: 2,
      checked: false,
      toast1: null,
      myInfoFlag: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {
    if (!localStorage.token) {
      this.$store.commit("setLogout");
    }
    // setTimeout(() => {
    //   this.WxLoginCall();
    // }, 80);
  },
  mounted() {
    this.statusBtn();
  },
  watch: {
    $route() {
      this.statusBtn();
    },
  },
  methods: {
    statusBtn() {
      if (this.$route.query.status) {
        this.formType = this.$route.query.status;
        if (this.formType == "3") {
          this.scanCode(uni.utils.StrQuery(location.href.split("#")[1], "code"));
        }
      }
    },
    showRead(i) {
      let ctx = "";
      if (i == "1") {
        ctx = "用户须知";
      } else {
        ctx = "隐私协议";
      }
      uni.showModal({
        content: ctx,
      });
    },
    WxLoginCall() {
      // 创建微信登录的对象
      new WxLogin({
        self_redirect: false, // 是否在iframe内跳转 redirect_uri
        id: "wechat-login", // 希望二维码嵌入容器的 id
        appid: "wx4cd63c671b414a77",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(location.origin + "/#/user/login?status=3"),
      });
    },
    getMsgCode() {
      if (!this.userForm.phone.match(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)) {
        uni.showToast({
          title: "请输入正确的手机号",
        });
        return;
      }
      if (this.getMsgDoor) return;
      this.getMsgDoor = true;
      this.sendCode();
      let num = 60;
      const loop = () => {
        if (num > 0) {
          num = num - 1;
          this.msgCodeTxt = num + "s";
          setTimeout(() => {
            if (!this.getMsgDoor) {
              num = 0;
            }
            loop();
          }, 1000);
        } else {
          this.getMsgDoor = false;
          this.msgCodeTxt = "获取验证码";
        }
      };
      loop();
    },
    scanCode(code) {
      this.$api.base
        .scanCode({
          code,
        })
        .then((res) => {
          if (res.openId) {
            this.userForm.openId = res.openId;
            sessionStorage.openId = this.userForm.openId;
          } else {
            this.loginSuccess(res);
          }
        })
        .catch(() => {
          if (sessionStorage.openId) {
            this.userForm.openId = sessionStorage.openId;
          } else {
            location.replace("/#/user/login");
          }
        });
    },
    sendCode() {
      this.$api.base
        .regCaptcha({
          mobile: this.userForm.phone,
        })
        .then((res) => {
          uni.showToast({
            title: "发送成功",
          });
        });
    },
    // AuthRegister() {
    //   let send = {
    //     username: this.userForm.phone,
    //     password: "123456",
    //     mobile: this.userForm.code,
    //     code: "1234",
    //     wxCode: "7ef294fe-79d1-4098-8050-5abefd1272c6"
    //   };
    //   this.$api.base.AuthRegister(send);
    // },
    loginSuccess(res) {
      let that = this
      localStorage.userInfo = JSON.stringify(res.userInfo);
      localStorage.token = res.token;
      

      this.$store.commit("setUser", res.userInfo);
      this.$store.commit("setToken", res.token);
      localStorage.removeItem("addressId");
      if (res.userInfo && res.userInfo.passwordRemind == 3) {
        that.getInfo()
        uni.showModal({
          title: "温馨提示",
          content: "您的密码已过期",
          showCancel: false,
          // cancelText: "稍后修改",
          confirmText: "立即修改",
          success: function(res) {
            if (res.confirm) {
              location.replace("/#/user/index?type=3");
            } else if (res.cancel) {
            }
          },
        });
        return 
      } else if (res.userInfo && res.userInfo.passwordRemind == 2) {
        that.getInfo()
        uni.showModal({
          title: "温馨提示",
          content: "密码过于简单有风险，请及时修改，谢谢支持",
          showCancel: false,
          // cancelText: "稍后修改",
          confirmText: "立即修改",
          success: function(res) {
            if (res.confirm) {
              location.replace("/#/user/index?type=3");
            } else if (res.cancel) {
            }
          },
        });
      } else {
        location.replace("/");
      }
      // this.$router.replace("/");
    },
    AuthLoginByAccount() {
      // if (!this.checked) {
      //   uni.showToast({
      //     title: "请先阅读同意条款",
      //   });
      //   return;
      // }
      if (!this.userForm.phone) {
        uni.showToast({
          title: "请输入账号",
        });
        return;
      }
      if (!this.userForm.code) {
        uni.showToast({
          title: "请输入密码",
        });
        return;
      }
      if (this.formType == "3") {
        this.AuthBindPhone();
        return;
      }
      this.$api.base
        // .AuthRegister({
        .AuthLoginByAccount({
          // code: this.userForm.code,
          // mobile: this.userForm.phone,
          username: this.userForm.phone,
          // password: this.userForm.code,
          password: Encrypt(this.userForm.code),
        })
        .then((res) => {
          localStorage.password = this.userForm.code;
          this.loginSuccess(res);
        });
    },
    AuthBindPhone() {
      if (!this.userForm.openId) {
        uni.showToast({
          title: "openId不存在",
        });
        return;
      }
      this.$api.base
        .AuthBindPhone({
          code: this.userForm.code,
          mobile: this.userForm.phone,
          openId: this.userForm.openId,
        })
        .then((res) => {
          this.loginSuccess(res);
        });
    },
     getInfo() {
      if (!localStorage.token) {
        this.myInfoFlag = true;
        this.$store.commit("SET_ROLEID", 7);
        this.$store.commit("SET_PERMISSIONS", []);
        return;
      }
      this.toast1 = uni.showToast({
        title: "页面授权中",
        duration: 2000000,
      });
      this.$api.base
        .getInfo()
        .then((res) => {
          this.myInfoFlag = true;
          if (this.toast1) {
            this.toast1.remove();
            this.toast1 = null;
          }
          if (res) {
            this.$store.commit("SET_ROLEID", res.dtsUser.roleId[0]);
            this.$store.commit("SET_PERMISSIONS", res.permissions);
            sessionStorage.departmentId = res.department.id;
            res.dtsUser.passwordRemind = res.passwordRemind || 0;
            this.$store.commit("setUser", res.dtsUser);
            localStorage.userInfo = JSON.stringify(res.dtsUser);
            localStorage.password = res.dtsUser.password;
          }
        })
        .catch(() => {
          this.myInfoFlag = true;
          if (this.toast1) {
            this.toast1.remove();
            this.toast1 = null;
          }
        });
    },
  },
};
</script>

<style>
#wechat-login iframe {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.zc-login {
  background-image: url(../../../assets/images/login1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--theme-color);
  height: 700px;
  min-width: 1200px;
}

.zc-login-panel {
  height: 700px;
  width: 1115px;
  box-sizing: border-box;
  padding-left: 700px;
  padding-top: 100px;
  margin: 0 auto;
}

.zc-login-form {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.zc-login-form h3 {
  font-size: 13.5pt;
}

.zc-login-item {
  border: solid 2px #ddd;
  display: flex;
  margin-top: 20px;
  border-radius: 5px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  font-size: 10.5pt;
}

.zc-login-item input {
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  height: 45px;
  outline: none;
}

.zc-login-icon {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
}

.zc-login-code {
  width: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: right;
  color: var(--theme-color);
  cursor: pointer;
}

.zc-login-button {
  background-color: var(--theme-color);
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  font-size: bold;
  margin-top: 20px;
  font-size: 10.5pt;
  text-decoration: none;
  cursor: pointer;
}
.formTabs {
  display: flex;
  width: 100%;
  .formTabs1 {
    flex: 1;
    // text-align: center;
    font-size: 20px;
    padding: 10px 0;
    color: #000;
    cursor: pointer;
  }
  .formTabs2 {
    span {
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 50px;
        height: 4px;
        border-radius: 4px;
        background: var(--theme-color);
      }
    }
  }
}
</style>
