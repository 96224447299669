<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-29 15:58:52
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-29 15:31:45
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\components\userMenu.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="yui3-u-1-6">
    <div class="zc-user-bar zc-user-base">
      <img class="zc-user-bar-figure" v-if="$store.state.roleId == 7" :src="require('../../assets/images/portrait.png')" />
      <img class="zc-user-bar-figure" v-else-if="$store.state.roleId == 8" :src="require('../../assets/images/portrait2.png')" />
      <img class="zc-user-bar-figure" v-else :src="require('../../assets/images/portrait1.png')" />

      <h3 class="zc-user-bar-name">{{ user.username }}</h3>
      <p class="zc-user-bar-welcome">欢迎光临泽超集采商城</p>
      <ul class="zc-user-bar-action">
        <li style="flex: 1" v-hasPermi="['user:order:collect']"><a class="button-solid" style="width: 100%" @click="$router.push({ path: '/user/favorite' })">我的收藏</a></li>
        <li v-if="$store.state.roleId != 7"><a class="button-block" style="margin-left: 10px" @click="$router.push({ path: '/user/address' })">地址管理</a></li>
      </ul>
    </div>

    <div class="zc-user-bar">
      <h3 class="zc-user-bar-title" @click="$router.push({ path: '/user/index?type=3' })" :class="{ navbarOn: $route.query.type == 3 }">
        账号中心
        <i class="el-icon-arrow-right"></i>
      </h3>
      <h3 class="zc-user-bar-title" @click="$router.push({ path: '/user/order/all' })" :class="{ navbarOn: $route.path === '/user/order/all' }" v-if="$store.state.roleId != 7">
        我的订单
        <i class="el-icon-arrow-right"></i>
      </h3>
      <h3 class="zc-user-bar-title" @click="$router.push({ path: '/user/index?type=1' })" :class="{ navbarOn: $route.query.type == 1 }" v-hasPermi="['user:order:settleDetail']">
        我的发票
        <i class="el-icon-arrow-right"></i>
      </h3>
      <h3 class="zc-user-bar-title" @click="$router.push({ path: '/user/index?type=2' })" :class="{ navbarOn: $route.query.type == 2 }" v-hasPermi="['user:order:settle']">
        结算中心
        <i class="el-icon-arrow-right"></i>
      </h3>

      <!-- <ul class="zc-user-bar-menu">
        <li><a @click="$router.push({ path: '/user/order/all' })" :class="{ 'zc-user-bar-menu-current': $route.params.mode == 'all' }">我的订单</a></li>
        <li><a @click="$router.push({ path: '/user/order/pay' })" :class="{ 'zc-user-bar-menu-current': $route.params.mode == 'pay' }">待付款</a></li>
        <li><a @click="$router.push({ path: '/user/order/send' })" :class="{ 'zc-user-bar-menu-current': $route.params.mode == 'send' }">待发货</a></li>
        <li><a @click="$router.push({ path: '/user/order/receive' })" :class="{ 'zc-user-bar-menu-current': $route.params.mode == 'receive' }">待收货</a></li>
        <li><a @click="$router.push({ path: '/user/order/service' })" :class="{ 'zc-user-bar-menu-current': $route.params.mode == 'service' }">售后中</a></li>
        <li><a @click="$router.push({ path: '/user/order/complete' })" :class="{ 'zc-user-bar-menu-current': $route.params.mode == 'complete' }">已完成</a></li> -->
      <!-- <li><a @click="$router.push({ path: '/user/order/evaluate' })">待评价</a></li> -->
      <!-- </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "commonUserMenu",
  props: {},
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.zc-user-bar {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.zc-user-base {
  padding: 20px;
}

.zc-user-bar-figure {
  margin: 0 auto;
  display: block;
}

.zc-user-bar-name {
  text-align: center;
  font-size: 12pt;
  font-weight: 400;
  margin-bottom: 0;
  color: #5e6063;
}

.zc-user-bar-welcome {
  text-align: center;
  margin: 0 0 20px;
  padding-bottom: 20px;
  color: #898b8d;
  border-bottom: solid 1px rgba(170, 172, 174, 0.3);
}

.zc-user-bar-title {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
  cursor: pointer;
  color: #898b8d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zc-user-bar-action {
  display: flex;
  list-style: none;
  justify-content: space-between;
}

.zc-user-bar-menu {
  list-style: none;
  margin: 0 20px;
  color: #898b8d;
  padding-bottom: 20px;
}

.zc-user-bar-menu li {
  border-bottom: solid 1px rgba(170, 172, 174, 0.3);
}

.zc-user-bar-menu li:last-child {
  border-bottom: none;
}

.zc-user-bar-menu a {
  display: block;
  padding: 10px 0 5px;
  text-decoration: none;
}

.zc-user-bar-menu a.zc-user-bar-menu-current {
  color: var(--theme-color);
}
.navbarOn {
  color: var(--theme-color);
}
</style>
