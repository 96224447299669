<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-29 15:58:52
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-09-01 09:43:12
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\components\userMenu.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="zc-product-card">
    <div class="zc-product-card-container" :class="{ 'zc-product-card-center': center }" :style="{ marginLeft: margin / 2 + 'px', marginRight: margin / 2 + 'px', marginBottom: margin + 'px', padding: padding + 'px', borderWidth: border + 'px' }" @click="onGoTo">
      <img class="zc-product-card-thumb" :src="thumb" :style="{ height: size + 'px' }" />
      <h3 class="zc-product-card-title" v-if="title">{{ title }}</h3>
      <div>
        <span class="zc-product-card-price" v-if="price != undefined">¥{{ price }}</span>
      </div>
      <div class="zc-product-card-disable" v-if="disable">
        <span>{{ disable }}</span>
      </div>
      <slot></slot>
      <p class="zc-product-card-remark" v-if="remark">{{ remark }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonProductCard",
  props: {
    thumb: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    price: {
      required: false,
    },
    remark: {
      type: String,
      required: false,
    },
    size: {
      default: 125,
      required: false,
    },
    margin: {
      default: 10,
      required: false,
    },
    padding: {
      default: 15,
      required: false,
    },
    border: {
      default: 0,
      required: false,
    },
    center: {
      type: Boolean,
      default: false,
      required: false,
    },
    disable: {
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onGoTo() {
      if (this.url && !this.disable) {
        this.$router.push(this.url);
      }
    },
  },
};
</script>

<style scoped>
.zc-product-card {
  position: relative;
}

.zc-product-card-disable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  color: rgba(0, 0, 0, 0.75);
  font-size: 9pt;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}

.zc-product-card-disable span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  height: 30px;
  background-color: #404040;
  color: #ccc;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.zc-product-card-container {
  border-style: solid;
  border-color: #ddd;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.zc-product-card-center {
  align-items: center;
}

.zc-product-card-thumb {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 125px;
}

.zc-product-card-title {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 10.5pt;
  line-height: 1.3em;
  height: 37px;
  margin: 10px 0 0;
  font-weight: 500;
  /* text-align: justify; */
}

.zc-product-card-price {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  padding: 0px 10px;
  background: var(--theme-color);
  border-radius: 4px;
  margin-bottom: 10px;
  display: inline-block;
}

.zc-product-card-remark {
  color: #999;
  margin: 10px 0 0;
}
</style>
