<template>
  <div style="background: #fff;border-bottom: 2px solid var(--theme-color)">
    <div class="py-container">
      <div class="navList1">
        <div>
          <div class="all-sort"></div>
          <div class="sort" v-if="complex">
            <div class="sort1">
              <div class="sort2">全部商品分类</div>
              <div class="sort3">
                <div class="item" v-for="(item, index) in categoryList" :key="index">
                  <h3>
                    <a>{{ item.name }}</a>
                    <i class="el-icon-arrow-right" v-if="item.children && item.children.length"></i>
                  </h3>
                  <div class="item-list">
                    <div class="subitem">
                      <dl class="fore" v-for="(item1, index1) in item.children" :key="index1">
                        <dt @click="$router.push('/product/catalog/' + item1.id)">
                          <a>{{ item1.name }}</a>
                          <i class="el-icon-arrow-right" v-if="item1.children && item1.children.length"></i>
                        </dt>
                        <template v-for="(item2, index2) in item1.children">
                          <dd @click="$router.push('/product/catalog/' + item2.id)" :key="index2" v-if="item2">
                            <a>{{ item2.name }}</a>
                          </dd>
                        </template>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navList2">
          <el-tabs>
            <el-tab-pane v-for="(item, index) in menuTopList" :key="index + '_a'">
              <span slot="label" :class="{ navOn: $route.query.id == item.id }" @click="jumpTopic(item, item.title)">{{ item.title }}</span>
            </el-tab-pane>
            <el-tab-pane v-for="(item, index) in articlesType" :key="index + '_b'">
              <span slot="label" :class="{ navOn: $route.query.art == item.id }" @click="navArtJump(item)">{{ item.title }}</span>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonNavigator",
  props: {
    complex: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      categoryList: [],
      currentCategory: {},
      currentSubCategoryList: [],
      articlesType: sessionStorage.articlesType ? JSON.parse(sessionStorage.articlesType) : [],
      toast1: null,
      menuTopList: sessionStorage.menuTop ? JSON.parse(sessionStorage.menuTop) : [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.CatalogDatas();
    this.homeTopicList();
    this.getArticles();
  },
  watch: {
    $route() {
      this.$emit("arts", this.articlesType);
    },
  },
  methods: {
    getArticles() {
      this.$api.base.getArticles().then((res) => {
        this.articlesType = res.articles.filter((v1) => {
          return v1.type == "2";
        });
        this.$emit("arts", this.articlesType);
        let fixarr = this.articlesType.map((v1) => {
          return {
            title: v1.title,
            id: v1.id,
          };
        });
        sessionStorage.articlesType = JSON.stringify(fixarr);
      });
    },
    jumpTopic(i, j, k) {
      if (i.thirdPartyUrl) {
        window.open(i.thirdPartyUrl);
        return;
      }
      let str = "/product/topic/";
      this.$router.push({
        path: str,
        query: {
          id: i ? i.id : j,
          type: j,
          integral: k ? k.id : "",
        },
      });
    },
    homeTopicList() {
      this.$api.base.homeTopicList().then((res) => {
        this.menuTopList = res.filter((v1) => {
          return v1.location == "3";
        });
        this.menuTopList.sort((a, b) => {
          return b.sortOrder - a.sortOrder;
        });
        sessionStorage.menuTop = JSON.stringify(this.menuTopList);
      });
    },
    navArtJump(i) {
      this.$router.push({
        path: "/product/notice",
        query: {
          art: i.id,
          type: i.title,
        },
      });
    },
    // navArea(i) {
    //   if (i === 1) {
    //     this.$router.push("/");
    //   }
    //   if (i === 2) {
    //     this.$router.push("/");
    //     this.$emit("navclick", 5);
    //     sessionStorage.navclick = "5";
    //   }
    //   if (i === 3) {
    //     this.$router.push("/");
    //     this.$emit("navclick", 3);
    //     sessionStorage.navclick = "3";
    //   }
    //   if (i === 4) {
    //     this.$router.push("/");
    //     this.$emit("navclick", 4);
    //     sessionStorage.navclick = "4";
    //   }
    //   if (i === 5) {
    //     uni.showToast({
    //       title: "正在开发中，敬请期待！",
    //     });
    //   }
    // },
    CatalogDatas() {
      this.$api.base.CatalogDatas().then((res) => {
        this.categoryList = res;
        if (this.categoryList && this.categoryList.length) {
          this.currentCategory = this.categoryList[0];
          if (this.currentCategory && this.currentCategory.children && this.currentCategory.children[0]) {
            this.currentSubCategoryList = this.currentCategory.children[0].children || [];
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.navList2 {
  .navOn {
    color: var(--theme-color);
  }
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs__item {
    font-size: 16px;
    color: #5e6063;
    font-weight: 700;
  }
  .el-tabs__nav-wrap::after,
  .el-tabs__active-bar {
    background: none;
    height: 0;
  }
  .el-tabs__item:hover {
    color: #5e6063;
  }
  .el-tabs__item.is-active {
    color: #5e6063;
  }
}
</style>

<style lang="scss" scoped>
.all-sort {
  width: 265px;
  background: none;
  height: 40px;
}
.sort {
  background: none;
  .sort1 {
    position: relative;
    border-radius: 8px;
    width: 188px;
    margin-top: 48px;
    background: #fff;
    height: 455px;
    padding: 27px 0;
  }
  .sort2 {
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 22px;
    padding: 0 24px 17px;
  }
  .sort3 {
    height: 415px;
    overflow: hidden auto;
  }
  .item {
    width: 188px;
    h3 {
      padding: 7px 24px;
      font-size: 12px;
      line-height: 17px;
      font-weight: 400;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a {
      color: #5e6063;
      text-decoration: none;
      font-size: 14px;
      line-height: 17px;
    }
    &:hover {
      background: var(--theme-color);
      cursor: pointer;
    }
    &:hover h3 a {
      color: #fff;
    }
    &:hover h3 i {
      color: #fff;
    }
    &:hover .item-list {
      display: block;
    }
  }
  .item-list {
    position: absolute;
    top: 0;
    left: 170px;
    border: 0;
    border-radius: 0 8px 8px 0;
    width: auto;
    background: #fafafa;
    padding: 66px 0 24px;
    height: 419px;
    display: none;
    .subitem {
      width: auto;
      padding: 0;
      height: 450px;
      overflow: hidden auto;
      .fore {
        padding: 0;
        margin: 0;
      }
      dl {
        display: flex;
      }
      dt,
      dd {
        margin: 0;
        width: 140px;
        text-align: left;
        padding: 7px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 17px;
        font-weight: 400;
        &:hover {
          background: var(--theme-color);
        }
        &:hover a {
          color: #fff;
        }
        &:hover i {
          color: #fff;
        }
      }
    }
  }
}
.navList1 {
  display: flex;
  align-items: center;
  .navList2 {
    flex: 1;
    width: 0;
  }
}
</style>
