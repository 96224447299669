<!--
 * @Author: xunmi muer3mu@126.com
 * @Date: 2022-08-26 17:33:26
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-26 17:56:01
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\page.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <mainHeader />
    <div style="background-color:#FFF;border-top:solid 1px #EEE">
      <div class="py-container" style="display:flex;padding:80px 0;">
        <img :src="require('@/assets/images/empty.png')" style="margin:30px 50px 0 130px" />
        <div style="line-height:1.7em">
          <h3>很抱歉，您查看的宝贝不存在，可能已下架或者被转移</h3>
          <p>您可以：</p>
          <ol style="margin-left:50px;font-size:10.5pt;">
            <li>该宝贝可能已经下架</li>
            <li>在顶部重新输入关键词搜索</li>
            <li>
              页面将在
              <span style="color:var(--theme-color);font-weight:bold;">{{ second }}</span>
              秒内自动跳转
            </li>
          </ol>
        </div>
      </div>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

export default {
  name: "orderPaySuccess",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
  },
  data() {
    return {
      second: 3,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {
    this.time = window.setInterval(() => {
      if (this.second == 0) {
        window.clearInterval(this.time);
        this.$router.replace("/home");
        return;
      }
      this.second--;
    }, 1000);
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.success {
  text-align: center;
  font-size: 16px;
}
</style>
