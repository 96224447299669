/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:09
 * @LastEditTime: 2022-09-29 15:52:49
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: 配置接口请求域名
 * @FilePath: \SuperMall\src\common\api\domain.js
 */

import { setResponseFormat } from "./axios";

const domain = {
  pc: "/wx",
};

if (process.env.NODE_ENV === "development") {
  domain.pc = "http://192.168.1.102:8401/wx";
  // domain.pc = "https://b2b.jszechao.com/wx";
}

const formatPC = (response) => {
  let object = {
    status: response.errno,
    message: response.errmsg,
  };

  object.data = response.data || response.rows;

  for (let key in response) {
    if (!~["data", "rows", "errno", "errmsg", "status", "message"].indexOf(key)) {
      object[key] = response[key];
    }
  }

  return object;
};

setResponseFormat(domain.pc + "/", formatPC);

export default domain;
