/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-08-17 14:34:13
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\store.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const config = {
  state: {
    user: localStorage["userInfo"] ? JSON.parse(localStorage["userInfo"]) : null,
    token: localStorage["token"],
    dynamicOrderRefund: null,
    permissions: sessionStorage.permissions ? JSON.parse(sessionStorage.permissions) : [],
    roleId: sessionStorage.roleId ? JSON.parse(sessionStorage.roleId) : "",
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    isLogin: (state) => {
      return !!state.token;
    },
    dynamicOrderRefund: (state) => {
      return state.dynamicOrderRefund;
    },
    permissions: (state) => state.permissions,
  },
  mutations: {
    setUser: (state, data) => {
      state.user = data;
      if (data.passwordRemind == 3 && location.hash.indexOf("/user/index?type=3") === -1) {
        setTimeout(() => {
          window.location.href = "/#/user/index?type=3";
        }, 200);
      }
    },
    setToken: (state, data) => {
      state.token = data;
    },
    setLogout: (state, data) => {
      state.user = null;
      state.token = "";

      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
    },
    setDynamicOrderRefund: (state, data) => {
      state.dynamicOrderRefund = data;

      setTimeout(() => {
        state.dynamicOrderRefund = null;
      }, 5000);
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
      sessionStorage.permissions = JSON.stringify(permissions);
    },
    SET_ROLEID: (state, roleId) => {
      state.roleId = roleId;
      sessionStorage.roleId = JSON.stringify(roleId);
    },
  },
  actions: {},
  modules: {},
};

export default new Vuex.Store(config);
