<template>
  <div>
    <mainHeader />
    <mainNavigator @arts="artsnav" />

    <div class="main">
      <div class="py-container">
        <div class="bread">
          <ul class="fl sui-breadcrumb">
            <li>
              <a @click="$router.push('/')">首页</a>
            </li>
            <li class="active">{{ this.$route.query.art ? "详情" : "公告" }}</li>
          </ul>
        </div>
        <div class="details">
          <div class="goods-list" v-if="!this.$route.query.art">
            <div style="text-align: center;font-size: 16px" v-if="!noticeList || !noticeList.length">
              <img style="width: 200px" src="../../../assets/images/n2.png" alt="" />
              <p>暂无公告</p>
            </div>
            <div class="partA-wrap" v-else>
              <div class="partA" v-for="(item, index) in noticeList" :key="index">
                <div class="partAa">
                  <div class="partAa-b" @click="showCtx(item)">
                    {{ item.title }}
                    <span class="partAa-b1">{{ item.addTime }}</span>
                  </div>
                  <div class="partAa-c" v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="goods-list" v-else>
            <div v-html="artInfo"></div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter />
  </div>
</template>

<script>
import "@/assets/css/pages-list.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";

export default {
  name: "productTopic",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    mainNavigator: mainNavigator,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  data() {
    return {
      noticeList: [],
      artInfo: null,
    };
  },
  mounted() {
    if (!this.$route.query.art) {
      this.getArticles();
    }
  },
  methods: {
    artsnav(i) {
      if (!this.$route.query.art) {
        this.getArticles();
      } else {
        i.forEach((v1) => {
          if (v1.id == this.$route.query.art) {
            this.artInfo = v1.content;
          }
        });
      }
    },
    getArticles() {
      this.$api.base.getArticles().then((res) => {
        this.noticeList = res.articles.filter((v1) => {
          return v1.type == "1";
        });
      });
    },
    showCtx(i) {
      if (i.content) {
        uni.showModal({
          title: "公告",
          content: i.content,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.part-empty {
  text-align: center;
}
.goods-list {
  background: #fff;
  border-radius: 4px;
  padding: 40px;
  font-size: 16px;
  line-height: 24px;
  .partA {
    font-size: 20px;
    font-weight: 600;
    color: #212223;
    line-height: 28px;
    border-bottom: 1px solid rgba(170, 172, 174, 0.3);
    padding-bottom: 24px;
    margin-bottom: 24px;
    &:last-of-type {
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
  .partAa {
    cursor: pointer;
  }
  .partAa-b1 {
    font-size: 16px;
    font-weight: 400;
    color: #b5b6b8;
    line-height: 22px;
    margin-left: 20px;
    vertical-align: bottom;
  }
  .partAa-c {
    font-size: 16px;
    font-weight: 400;
    color: #898b8d;
    line-height: 25px;
    margin-top: 8px;
    max-height: 50px;
    overflow: hidden;
  }
}
</style>
