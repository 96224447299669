<template>
  <div style="background-color:#FFF;">
    <div class="zc-ceil">
      <div class="py-container">
        <ul>
          <li>
            <!-- 工银安盛 -->
            欢迎您
            <template v-if="user">
              {{ user.username }} 用户，
              <a @click="onLogout">退出登录</a>
            </template>
            <a v-else @click="$router.push({ path: '/user/login' })">请登录</a>
          </li>
          <li style="padding-left: 20px;" v-if="user && user.passwordRemind == 1">
            您的密码快过期了，请尽快
            <span style="color: var(--theme-color);cursor: pointer;" @click.stop="showPWBtn">修改</span>
          </li>
          <li class="notice-left" v-if="noticeList.length">公告：</li>
          <li class="notice0">
            <div class="notice1" :style="{ transform: `translateY(-${noticeIndex * 20}px)`, transition: noticeIndex !== 0 ? `all 0.5s linear` : '' }" @click="$router.push('/product/notice')">
              <div class="notice2" v-for="(item, index) in noticeList" :key="index" :style="{ transform: `translateY(${index * 20}px)` }">{{ item.title }}</div>
            </div>
          </li>
        </ul>
        <ul class="zc-ceil-menu">
          <li><a @click="$router.push({ path: '/user/index' })">我的账户</a></li>
          <li v-if="$store.state.roleId != 7">
            <a @click="$router.push({ path: '/user/order/all' })">我的订单</a>
          </li>
          <li v-hasPermi="['user:order:collect']"><a @click="$router.push({ path: '/user/favorite' })">我的收藏</a></li>
          <li v-if="$store.state.roleId != 7">
            <a @click="$router.push({ path: '/user/address' })">我的地址</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="py-container">
      <div class="zc-header">
        <a class="zc-header-logo" @click="$router.push({ path: '/home' })"></a>
        <div class="zc-header1">
          <form class="zc-header-form">
            <div class="zc-header-search">
              <img class="zc-header-icon" :src="require('../../assets/images/search.png')" />
              <input type="text" v-model="keywords" @change="onSearch(keywords)" />
              <button type="button" @click="onSearch(keywords)">搜索</button>
            </div>
            <div class="zc-header-keyword" v-if="hotKeywordList && hotKeywordList.length">
              <a v-for="(item, index) in hotKeywordList" :key="index" @click="onSearch(item.keyword)">{{ item.keyword }}</a>
            </div>
          </form>
          <a class="zc-header-cart" @click="$router.push({ path: '/user/cart' })">
            <img class="zc-header-icon" :src="require('../../assets/images/cart.png')" />
            我的购物车
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonHeader",
  data() {
    return {
      hotKeywordList: [],
      keywords: null,
      noticeList: [],
      noticeIndex: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.$api.base.SearchIndex().then((res) => {
      this.hotKeywordList = res.hotKeywordList;
    });

    this.$api.base.getArticles().then((res) => {
      if (res.articles) {
        this.noticeList = res.articles.filter((v1) => {
          return v1.type == "1";
        });
        if (this.noticeList[0]) {
          this.noticeList.push(this.noticeList[0]);
        }
      }
    });

    this.keywords = this.$route.params.keywords?.replace(/,+/gi, " ");

    const loop = () => {
      let timeout = 3000;
      if (this.noticeList.length) {
        this.noticeIndex += 1;
        if (this.noticeIndex >= this.noticeList.length) {
          this.noticeIndex = 0;
          timeout = 0;
        }
      }
      setTimeout(() => {
        loop();
      }, timeout);
    };
    loop();
  },
  methods: {
    showPWBtn() {
      window.location.href = "/#/user/index?type=3";
      // uni.showModal({
      //   title: "温馨提示",
      //   content: "请联系管理员修改密码，谢谢您的支持",
      //   showCancel: false,
      //   // cancelText: "稍后修改",
      //   confirmText: "确认",
      //   success: function(res) {
      //     if (res.confirm) {
      //     } else if (res.cancel) {
      //     }
      //   },
      // });
    },
    onSearch(keywords) {
      this.keywords = (keywords || this.keywords || "").trim();

      this.$router.push({ path: `/product/search/${this.keywords.trim().split(/[\s|\/]+/gi)}` });

      /* if (this.keywords) {
        this.$router.push({ path: `/product/search/${this.keywords.trim().split(/[\s|\/]+/gi)}` });
      } else {
        uni.showToast({
          title: "请输入搜索关键字",
        });
      } */

      this.$emit("searcher", this.keywords);
    },
    onLogout() {
      this.$api.base.AuthLogout().then((res) => {
        this.$store.commit("setLogout");
        this.$router.replace("/");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zc-ceil {
  background-color: #f5f5f5;
  line-height: 1;
}

.zc-ceil .py-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.zc-ceil-menu {
  transform: translateX(15px);
}

.zc-ceil-menu li {
  padding: 0 15px;
  border-left: 1px solid #ccc;
}

.zc-ceil-menu li:first-child {
  border-left: none;
}

.zc-ceil ul {
  display: flex;
  list-style: none;
}

.zc-ceil ul li {
}
.zc-header1 {
  display: flex;
  justify-content: space-between;
  flex: 1;
  height: 60px;
}
.zc-header {
  height: 100px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zc-header-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}

.zc-header-logo {
  display: block;
  background-image: url(../../assets/img/Logo.jpg);
  background-repeat: no-repeat;
  background-position: center;
  width: 208px;
  height: 100%;
  background-size: contain;
  margin-right: 80px;
}

.zc-header-form {
  flex-grow: 1;
}

.zc-header-search {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding: 2px;
  border: solid 2px var(--theme-color);
  border-radius: 22px;
  overflow: hidden;
  display: flex;
  padding-left: 20px;
  align-items: center;
}

.zc-header-search input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  flex-shrink: 1;
  flex-grow: 1;
  font-size: 14px;
}

.zc-header-search button {
  border: none;
  outline: none;
  display: block;
  width: 100px;
  height: 100%;
  background: var(--theme-color);
  border-radius: 19px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}

.zc-header-keyword {
  margin-left: 23px;
}

.zc-header-keyword span,
.zc-header-keyword a {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 14px;
  color: #b5b6b8;
}

.zc-header-cart {
  width: 160px;
  height: 44px;
  box-sizing: border-box;
  border: solid 2px var(--theme-color);
  border-radius: 22px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  margin-left: 30px;
}
.notice-left {
  padding-left: 20px;
  color: var(--theme-color);
}
.notice0 {
  height: 20px;
  width: 400px;
  overflow: hidden;
  position: relative;
  color: var(--theme-color);
  .notice1 {
    .notice2 {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
</style>
