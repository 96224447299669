<template>
  <div>
    <mainHeader />
    <mainNavigator />

    <div class="main">
      <div class="py-container">
        <!--bread-->
        <div class="bread">
          <ul class="fl sui-breadcrumb">
            <li>
              <a @click="$router.push('/')">首页</a>
            </li>
            <li class="active">分类</li>
          </ul>
          <!-- <ul class="fl sui-tag">
            <li class="with-x">
              iphone
              <i>×</i>
            </li>
          </ul>
          <form class="fl sui-form form-dark">
            <div class="input-control control-right">
              <input type="text" />
              <i class="sui-icon icon-touch-magnifier"></i>
            </div>
          </form> -->
        </div>
        <!--selector-->
        <div class="zc-filter">
          <span>{{ navTitle }}：</span>
          <ul>
            <li v-for="(item, index) in navList" :key="index" :class="id == item.id ? 'active' : ''" :data-id="item.id" :data-index="index" @click="switchCate">
              <a>{{ item.name }}</a>
            </li>
          </ul>
        </div>

        <div class="zc-sub-filter">
          <ul>
            <li :class="{ active: sort == 'sort_order' }" @click="onSort('sort_order', 'desc')">综合</li>
            <li :class="{ active: sort == 'sales' }" @click="onSort('sales', 'asc')">销量</li>
            <li class="zc-filter-order" :class="{ active: sort == 'retail_price', asc: order == 'asc', desc: order == 'desc' }" @click="onSort('retail_price')">价格</li>
          </ul>
          <!-- <div class="zc-sub-search">
						<input type="text" v-model="keywords" placeholder="请输入商品标题/商品编码" @change="onSearch(keywords)" />
						<img :src="require('../../../assets/images/search.png')" @click="onSearch(keywords)" />
					</div> -->
          <div class="search-r1">
            <div class="search-r2">
              ￥
              <input class="search-r2a" v-model="minPrice" @blur="minPriceInput" type="text" />
            </div>
            <span class="search-r3"></span>
            <div class="search-r4">
              <div class="search-r2">
                ￥
                <input class="search-r2a" v-model="maxPrice" @blur="maxPriceInput" type="text" />
              </div>
              <img class="search-r5" :src="require('../../../assets/images/search.png')" @click="onSearch()" />
            </div>
          </div>
        </div>

        <div class="details">
          <!-- <div class="goods-list" v-if="toast1">
            <el-empty description="加载中"></el-empty>
          </div> -->
          <div class="goods-list">
            <p class="zc-panel-empty" v-if="!goodsList || !goodsList.length">
              <img :src="require('@/assets/images/n2.png')" />
              <strong>暂无商品</strong>
            </p>
            <div v-else>
              <ul class="partA-wrap">
                <li class="partA" v-for="(iitem, iindex) in goodsList" :key="iindex" @click="jumpGoods(iitem)">
                  <div class="partAa">
                    <img class="partAa-a" :src="iitem.picUrl" alt="" />
                    <div class="partAa-b">{{ iitem.name }}</div>
                    <div class="partAa-c">
                      ￥
                      <b>{{ iitem.retailPrice }}</b>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="zc-panel-footer">
                <commonPagination :total="totalCount" :load="getGoodsList"></commonPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter />
  </div>
</template>

<script>
import "@/assets/css/pages-list.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";
import commonPagination from "@/pc/components/pagination";

export default {
  name: "productCatalog",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    mainNavigator: mainNavigator,
    commonPagination: commonPagination,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  data() {
    return {
      keywords: undefined,
      navTitle: "",
      navList: [],
      goodsList: [],
      childrenCategoryNum: 0,
      childrenCategory: [],
      childrenCategoryList: [],
      id: 0,
      categoryId: 0,
      currentCategory: {},
      scrollLeft: 0,
      scrollTop: 0,
      scrollHeight: 0,
      isAgent: false,
      minPrice: "",
      maxPrice: "",
      page: 1,
      size: 10,
      sort: "sort_order",
      order: "desc",
      totalPages: 1,
      totalCount: 1,
      toast1: false,
    };
  },
  mounted: function() {
    let options = this.$route.params;
    // 页面初始化 options为页面跳转所带来的参数
    let that = this;

    if (options.id) {
      that.setData({
        id: parseInt(options.id),
      });
    }

    this.categoryId = this.id;

    this.getCategoryInfo();
  },
  methods: {
    minPriceInput() {
      let aa = Number(this.minPrice || 0);
      let bb = Number(this.maxPrice || 0);
      if (aa < bb) {
        setTimeout(() => {
          this.maxPrice = "";
        });
      }
    },
    maxPriceInput() {
      let aa = Number(this.minPrice || 0);
      let bb = Number(this.maxPrice || 0);
      if (aa > bb) {
        setTimeout(() => {
          this.maxPrice = "";
        });
      }
    },
    jumpGoods(i) {
      let str = "/product/detail/";
      // let str = "/product/catalog/";
      if (i.goods) {
        str += i.goods.id;
      } else {
        str += i.id;
      }
      this.$router.push(str);
    },
    getCategoryInfo: function() {
      let that = this;

      this.$api.base
        .GoodsCategory({
          id: this.id,
        })
        .then(function(res) {
          let fixarr = [];
          if (res.childrenCategory && res.childrenCategory.length) {
            fixarr = res.childrenCategory[0]?.children || [];
            that.categoryId = fixarr[0]?.id || 0;
          }
          that.childrenCategoryList = res.childrenCategory;
          that.setData({
            navList: res.brotherCategory,
            childrenCategory: fixarr,
            currentCategory: res.currentCategory,
            navTitle: res.parentCategory.name,
          });
          uni.setNavigationBarTitle({
            title: res.parentCategory.name,
          }); // 当id是L1分类id时，这里需要重新设置成L1分类的一个子分类的id

          if (res.parentCategory.id == that.id) {
            that.setData({
              id: res.currentCategory.id,
            });
          } //nav位置

          // let currentIndex = 0;
          // let navListCount = that.navList.length;

          // for (let i = 0; i < navListCount; i++) {
          //   currentIndex += 1;

          //   if (that.navList[i].id == that.id) {
          //     break;
          //   }
          // }

          // if (currentIndex > navListCount / 2 && navListCount > 5) {
          //   that.setData({
          //     scrollLeft: currentIndex * 60
          //   });
          // }

          that.getGoodsList();
        });
    },
    getGoodsList: function(page) {
      let that = this;
      this.toast1 = true;

      this.$api.base
        .GoodsList({
          keyword: this.keywords,
          categoryId: that.categoryId,
          page: page,
          size: that.size,
          order: this.order,
          sort: this.sort,
          minPrice: that.minPrice,
          maxPrice: that.maxPrice,
        })
        .then((res) => {
          this.toast1 = false;
          that.setData({
            goodsList: res.goodsList,
            totalPages: res.totalPages,
            totalCount: res.totalCount,
          });
        })
        .catch(() => {
          this.toast1 = false;
        });
    },
    switchCateItem(i, j) {
      this.childrenCategoryNum = j;
      this.categoryId = i.id;
      this.page = 1;
      this.goodsList = [];
      this.totalPages = 1;
      this.sort = "sort_order";
      this.order = "desc";
      this.getGoodsList();
    },
    switchCate: function(event) {
      // if (this.id == event.currentTarget.dataset.id) {
      //   return false;
      // }
      this.childrenCategoryNum = 0;

      let that = this;
      let clientX = event.detail.x;
      let currentTarget = event.currentTarget;

      if (clientX < 60) {
        that.setData({
          scrollLeft: currentTarget.offsetLeft - 60,
        });
      } else if (clientX > 330) {
        that.setData({
          scrollLeft: currentTarget.offsetLeft,
        });
      }

      this.setData({
        id: event.currentTarget.dataset.id,
        categoryId: event.currentTarget.dataset.id,
        goodsList: [],
        page: 1,
        totalPages: 1,
      });

      let temp = this.childrenCategoryList[event.currentTarget.dataset.index] || {};
      if (temp.children && temp.children.length) {
        this.childrenCategory = temp.children;
        this.categoryId = this.childrenCategory[0].id;
      }

      that.getGoodsList();

      // this.getCategoryInfo();
    },
    onSort(sort, order) {
      if (order) {
        this.sort = sort;
      } else {
        this.order = this.order == "asc" ? "desc" : "asc";
      }

      this.sort = sort;
      this.page = 1;
      this.goodsList = [];
      this.totalPages = 1;
      this.getGoodsList();
    },
    onSearch(keywords) {
      this.keywords = (keywords || this.keywords || "").trim();

      this.sort = "sort_order";
      this.order = "desc";
      this.page = 1;
      this.goodsList = [];
      this.totalPages = 1;
      this.getGoodsList();

      /* if (this.keywords)
			{
				this.sort = 'sort_order';
				this.order = 'asc';
				this.page = 1;
				this.goodsList = [];
				this.totalPages = 1;
				this.getGoodsList();
			} else
			{
				uni.showToast({
					title: "请输入搜索关键字",
				});
			} */
    },
  },
};
</script>

<style lang="scss" scoped>
.search-r1 {
  display: flex;
  align-items: center;
}
.search-r1 input {
  border: 0;
  background: none;
  outline: none;
}
.search-r2 {
  border-radius: 19px;
  border: 1px solid rgba(170, 172, 174, 0.3);
  font-size: 16px;
  color: #b5b6b8;
  line-height: 16px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #f4f4f4;
}
.search-r2a {
  height: 28px;
  line-height: 28px;
  width: 80px;
}
.search-r3 {
  display: inline-block;
  width: 12px;
  height: 1px;
  margin: 0 5px;
  background: rgba(170, 172, 174, 0.3);
}
.search-r4 {
  display: flex;
  align-items: center;
  background: rgba(181, 182, 184, 0.3);
  border-radius: 80px;
}
.search-r5 {
  width: 14px;
  height: 14px;
  padding: 7px 8px;
  cursor: pointer;
}
.zc-filter {
  .active a {
    color: var(--theme-color);
  }
}
.type-wrap {
  display: flex;
  .value {
    flex: 1;
    background: #fff;
  }
}
.part-empty {
  text-align: center;
}
.partA-wrap {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  width: 100%;
  .partA {
    width: 180px;
    list-style: none;
    padding: 0 20px;
    background: #fff;
    margin: 20px 10px;
    cursor: pointer;
  }
  .partAa-a {
    width: 180px;
    height: 180px;
  }
  .partAa-b {
    height: 50px;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .partAa-c {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    padding: 0px 10px;
    background: var(--theme-color);
    border-radius: 4px;
    margin-bottom: 10px;
    display: inline-block;
  }
}
</style>
