/*
 * @Author: xunmi muer3mu@126.com
 * @Date: 2022-09-07 09:54:27
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-09-07 11:18:50
 * @FilePath: \SuperMalld:\Projects\工银安盛商城\client\src\common\directives\hasPermi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import store from "../../pc/store";

export default {
  inserted(el, binding, vnode) {
    const { value } = binding;
    const all_permission = "*:*:*";
    const permissions = store.getters && store.getters.permissions;

    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value;

      const hasPermissions = permissions.some((permission) => {
        return all_permission === permission || permissionFlag.includes(permission);
      });

      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`请设置操作权限标签值`);
    }
  },
};
