<template>
  <div>
    <mainHeader @searcher="getSearchResult" />
    <mainNavigator />

    <div class="main">
      <div class="py-container">
        <!--bread-->
        <div class="bread">
          <ul class="fl sui-breadcrumb">
            <li>
              <a @click="$router.push('/')">首页</a>
            </li>
            <li class="active">{{ ($route.params.keywords || "").replace(/,+/gi, " ") }}</li>
          </ul>
        </div>

        <div class="zc-sub-filter">
          <ul>
            <li :class="{ active: sort == 'sort_order' }" @click="onSort('sort_order', 'desc')">综合</li>
            <li :class="{ active: sort == 'sales' }" @click="onSort('sales', 'asc')">销量</li>
            <li class="zc-filter-order" :class="{ active: sort == 'retail_price', asc: order == 'asc', desc: order == 'desc' }" @click="onSort('retail_price')">价格</li>
          </ul>
          <!-- <div class="zc-sub-search">
						<input type="text" v-model="keywords" placeholder="请输入商品标题/商品编码" @change="onSearch(keywords)" />
						<img :src="require('../../../assets/images/search.png')" @click="onSearch(keywords)" />
					</div> -->
          <div class="search-r1">
            <div class="search-r2">
              ￥
              <input class="search-r2a" v-model="minPrice" @blur="minPriceInput" type="text" />
            </div>
            <span class="search-r3"></span>
            <div class="search-r4">
              <div class="search-r2">
                ￥
                <input class="search-r2a" v-model="maxPrice" @blur="maxPriceInput" type="text" />
              </div>
              <img class="search-r5" :src="require('../../../assets/images/search.png')" @click="onSearch()" />
            </div>
          </div>
        </div>

        <div class="goods-list">
          <p class="zc-panel-empty" v-if="!goodsList || !goodsList.length">
            <img :src="require('@/assets/images/n2.png')" />
            <strong>暂无商品</strong>
          </p>
          <div v-else>
            <span class="yui3-u-1-5" v-for="(iitem, iindex) in goodsList" :key="iindex" @click="jumpGoods(iitem)">
              <productCard :thumb="iitem.picUrl" :title="iitem.name" size="200" :price="iitem.retailPrice" />
            </span>

            <div class="zc-panel-footer">
              <commonPagination :total="totalCount" :load="getGoodsList"></commonPagination>
            </div>
          </div>
        </div>

        <div class="zc-panel" v-if="hots && hots.length">
          <div class="zc-panel-header">
            <h3 class="zc-panel-header-title">为您推荐</h3>
          </div>
          <div class="zc-panel-container">
            <div class="zc-panel-container zc-panel-container-row">
              <template v-for="(item, index) in hots">
                <productCard class="yui3-u-1-4" :url="`/product/detail/${item.id}`" :thumb="item.picUrl" :title="item.name" :price="item.retailPrice" :key="index" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter />
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";
import commonPagination from "@/pc/components/pagination";

import commonSlider from "@/pc/components/slider";
import productCard from "@/pc/components/productCard";

export default {
  name: "productSearch",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    mainNavigator: mainNavigator,
    commonSlider: commonSlider,
    commonPagination: commonPagination,
    productCard: productCard,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  data() {
    return {
      keyword: "",
      searchStatus: false,
      goodsList: [],
      helpKeyword: [],
      historyKeyword: [],
      categoryFilter: false,
      currentSort: "name",
      currentSortType: "default",
      currentSortOrder: "desc",
      filterCategory: [],
      defaultKeyword: {},
      hotKeyword: [],
      isAgent: false,
      isSearch: true,
      minPrice: "",
      maxPrice: "",
      page: 1,
      size: 10,
      sort: "sort_order",
      order: "desc",
      categoryId: 0,
      totalPages: 1,
      totalCount: 1,
      hots: [],
    };
  },
  // watch: {
  //   $route(val) {
  //     this.getSearchResult(this.$route.params.keywords.replace(",", " "));
  //   }
  // },
  props: {},
  mounted() {
    this.getHotList();
    console.log(2222);
    this.getSearchResult((this.$route.params.keywords || "").replace(",", " "));
  },
  methods: {
    minPriceInput() {
      let aa = Number(this.minPrice || 0);
      let bb = Number(this.maxPrice || 0);
      if (aa < bb) {
        setTimeout(() => {
          this.maxPrice = "";
        });
      }
    },
    maxPriceInput() {
      let aa = Number(this.minPrice || 0);
      let bb = Number(this.maxPrice || 0);
      if (aa > bb) {
        setTimeout(() => {
          this.maxPrice = "";
        });
      }
    },
    jumpGoods(i) {
      let str = "/product/detail/";
      // let str = "/product/catalog/";
      if (i.goods) {
        str += i.goods.id;
      } else {
        str += i.id;
      }
      this.$router.push(str);
    },
    //事件处理函数
    closeSearch: function() {
      uni.navigateBack();
    },
    clearKeyword: function() {
      this.setData({
        keyword: "",
        searchStatus: false,
      });
    },

    getSearchKeyword() {
      let that = this;

      this.$api.base.SearchIndex().then(function(res) {
        that.setData({
          historyKeyword: res.historyKeywordList,
          defaultKeyword: res.defaultKeyword,
          hotKeyword: res.hotKeywordList,
        });
      });
    },

    inputChange: function(e) {
      this.setData({
        keyword: e.detail.value,
        searchStatus: false,
      });

      if (e.detail.value) {
        this.getHelpKeyword();
      }
    },
    getHelpKeyword: function() {
      let that = this;
      util
        .request(api.SearchHelper, {
          keyword: that.keyword,
        })
        .then(function(res) {
          if (res.errno === 0) {
            that.setData({
              helpKeyword: res.data,
            });
          }
        });
    },
    inputFocus: function() {
      this.setData({
        searchStatus: false,
        goodsList: [],
      });

      if (this.keyword) {
        this.getHelpKeyword();
      }
    },
    clearHistory: function() {
      this.setData({
        historyKeyword: [],
      });
      util.request(api.SearchClearHistory, {}, "POST").then(function(res) {
        console.log("清除成功");
      });
    },
    getGoodsList: function(page) {
      let that = this;

      this.$api.base
        .GoodsList({
          keyword: that.keyword,
          page: page || 1,
          size: that.size,
          order: this.order,
          sort: this.sort,
          categoryId: that.categoryId,
          minPrice: that.minPrice,
          maxPrice: that.maxPrice,
        })
        .then(function(res) {
          that.setData({
            searchStatus: true,
            categoryFilter: false,
            goodsList: that.isSearch ? res.goodsList : res.goodsList,
            filterCategory: res.filterCategoryList,
            totalPages: res.totalPages,
            totalCount: res.totalCount,
            isSearch: false,
          });
          //重新获取关键词
          that.goodsList = that.goodsList.map((v) => {
            v.picUrl = v.picUrl || "";
            return v;
          });

          that.getSearchKeyword();
        });
    },
    onKeywordTap: function(event) {
      this.getSearchResult(event.target.dataset.keyword);
    },

    getSearchResult(keyword) {
      /* if (keyword === "")
			{
				keyword = this.defaultKeyword.keyword;
			} */

      this.setData({
        keyword: keyword,
        page: 1,
        totalPages: 1,
        categoryId: 0,
        goodsList: [],
      });
      this.getGoodsList();
    },

    openSortFilter: function(event) {
      let currentId = event.currentTarget.id;

      switch (currentId) {
        case "categoryFilter":
          this.setData({
            categoryFilter: !this.categoryFilter,
            currentSortType: "category",
            currentSort: "add_time",
            currentSortOrder: "desc",
            isSearch: true,
          });
          break;

        case "priceSort":
          let tmpSortOrder = "asc";

          if (this.currentSortOrder == "asc") {
            tmpSortOrder = "desc";
          }

          this.setData({
            currentSortType: "price",
            currentSort: "retail_price",
            currentSortOrder: tmpSortOrder,
            categoryFilter: false,
            isSearch: true,
          });
          this.getGoodsList();
          break;

        default:
          //综合排序
          this.setData({
            currentSortType: "default",
            currentSort: "name",
            currentSortOrder: "desc",
            categoryFilter: false,
            categoryId: 0,
            isSearch: true,
          });
          this.getGoodsList();
      }
    },
    selectCategory: function(event) {
      let currentIndex = event.target.dataset.categoryIndex;
      let filterCategory = this.filterCategory;
      let currentCategory = null;

      for (let key in filterCategory) {
        if (key == currentIndex) {
          filterCategory[key].selected = true;
          currentCategory = filterCategory[key];
        } else {
          filterCategory[key].selected = false;
        }
      }

      this.setData({
        filterCategory: filterCategory,
        categoryFilter: false,
        categoryId: currentCategory.id,
        page: 1,
        totalPages: 1,
        goodsList: [],
      });
      this.getGoodsList();
    },

    onKeywordConfirm(event) {
      this.getSearchResult(event.detail.value);
    },
    getHotList() {
      this.$api.base.HotGoods({ size: 4 }).then((response) => {
        this.hots = response.hotGoodsList.map((item) => {
          item.goods.retailPrice = Math.min(...item.products.map((i) => i.price));
          return item.goods;
        });
      });
    },
    onSort(sort, order) {
      if (order) {
        this.sort = sort;
      } else {
        this.order = this.order == "asc" ? "desc" : "asc";
      }

      this.sort = sort;
      this.page = 1;
      this.goodsList = [];
      this.totalPages = 1;
      this.getGoodsList();
    },
    onSearch(keywords) {
      this.keywords = (keywords || this.keywords || "").trim();

      this.sort = "sort_order";
      this.order = "desc";
      this.page = 1;
      this.goodsList = [];
      this.totalPages = 1;
      this.getGoodsList();

      /* if (this.keywords)
			{
				this.sort = 'sort_order';
				this.order = 'asc';
				this.page = 1;
				this.goodsList = [];
				this.totalPages = 1;
				this.getGoodsList();
			} else
			{
				uni.showToast({
					title: "请输入搜索关键字",
				});
			} */
    },
  },
};
</script>

<style lang="scss" scoped>
.search-r1 {
  display: flex;
  align-items: center;
}
.search-r1 input {
  border: 0;
  background: none;
  outline: none;
}
.search-r2 {
  border-radius: 19px;
  border: 1px solid rgba(170, 172, 174, 0.3);
  font-size: 16px;
  color: #b5b6b8;
  line-height: 16px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #f4f4f4;
}
.search-r2a {
  height: 28px;
  line-height: 28px;
  width: 80px;
}
.search-r3 {
  display: inline-block;
  width: 12px;
  height: 1px;
  margin: 0 5px;
  background: rgba(170, 172, 174, 0.3);
}
.search-r4 {
  display: flex;
  align-items: center;
  background: rgba(181, 182, 184, 0.3);
  border-radius: 80px;
}
.search-r5 {
  width: 14px;
  height: 14px;
  padding: 7px 8px;
  cursor: pointer;
}
.part-empty {
  text-align: center;
  padding: 20px 0;
}
</style>
