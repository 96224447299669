<template>
  <div v-show="myInfoFlag">
    <router-view />
  </div>
</template>

<script>
import "@/assets/css/all.css";

export default {
  name: "App",
  data() {
    return {
      myInfoFlag: false,
      toast1: null,
    };
  },
  watch: {
    $route(to,from){
      if(from.path != "/user/login" && this.user && this.user.passwordRemind == "3"){
        location.replace("/#/user/index?type=3");
        this.$notify.error({
            title: '失败',
            message: "请先修改密码！"
				})   
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  created() {
    this.getInfo();
    console.log(this.user,"user");
  },
  methods: {
    getInfo() {
      if (!localStorage.token) {
        this.myInfoFlag = true;
        this.$store.commit("SET_ROLEID", 7);
        this.$store.commit("SET_PERMISSIONS", []);
        return;
      }
      this.toast1 = uni.showToast({
        title: "页面授权中",
        duration: 2000000,
      });
      this.$api.base
        .getInfo()
        .then((res) => {
          this.myInfoFlag = true;
          if (this.toast1) {
            this.toast1.remove();
            this.toast1 = null;
          }
          if (res) {
            this.$store.commit("SET_ROLEID", res.dtsUser.roleId[0]);
            this.$store.commit("SET_PERMISSIONS", res.permissions);
            sessionStorage.departmentId = res.department.id;
            res.dtsUser.passwordRemind = res.passwordRemind || 0;
            this.$store.commit("setUser", res.dtsUser);
            localStorage.userInfo = JSON.stringify(res.dtsUser);
            localStorage.password = res.dtsUser.password;
          }
        })
        .catch(() => {
          this.myInfoFlag = true;
          if (this.toast1) {
            this.toast1.remove();
            this.toast1 = null;
          }
        });
    },
    addPayMsg() {
      let payWS = null;
      let timer = null;
      const wsAdd = () => {
        if (localStorage.token) {
          //payWS = new uni.ws("ws://192.168.1.135:8088" + this.$api.base.imserver);
          payWS = new uni.ws("ws://" + location.host + this.$api.base.imserver);
          payWS.init({
            close: () => {
              clearInterval(timer);
              payWS = null;
              timer = null;
              console.log("重新链接");
              setTimeout(() => {
                wsAdd();
              }, 5000);
            },
            open: () => {
              timer = setInterval(() => {
                payWS.send({
                  heartbeat: new Date().getTime(),
                  token: localStorage.token,
                });
              }, 300000);
              payWS.push((data) => {
                console.log(data);
                if (data && data.resultCode && data.appid) {
                  uni.showModal({
                    showCancel: true,
                    // confirmText: "确认",
                    content: `<p style="font-size: 20px">微信支付已完成</p>`,
                    success: () => {
                      this.$router.replace("/user/order/all");
                    },
                  });
                }
              });
            },
          });
        }
      };
      wsAdd();
    },
  },
};
</script>

<style>
:root {
  --theme-color: #c4121a;
}
td a {
  line-height: 1.4;
}
.color1 {
  color: #898b8d;
}
img:hover {
  opacity: 1 !important;
}
.goods-list ul li:hover {
  box-shadow: none !important;
}
.icon-more:after {
  content: "›";
  display: inline-block;
  font-size: 15pt;
  margin-left: 5px;
  position: relative;
  transition: all 0.25s ease;
  top: -1px;
}

.icon-more:hover {
  text-decoration: none;
  color: #111 !important;
}

.zc-main {
  padding-left: 20px;
  box-sizing: border-box;
}

.zc-panel {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.zc-panel-footer {
}

.zc-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.zc-panel-header-fixed {
  background-color: #fafafa;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 20px;
  height: 75px;
  box-sizing: border-box;
}

.zc-panel-header-fixed + * {
  padding-top: 75px;
}

.zc-panel-header-border {
  border-bottom: solid 1px #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.zc-panel-header-title {
  font-size: 20px;
  font-weight: 400;
  color: #3c3e40;
  margin: 0;
  line-height: 1;
  font-weight: bold;
}

.zc-panel-header-title strong {
  display: block;
  font-weight: inherit;
}

.zc-panel-header-title small {
  display: block;
  color: #999;
  margin-top: 5px;
  font-weight: normal;
}

.zc-panel-header-sub-title {
  font-size: 10.5pt;
  font-weight: 400;
  color: #3c3e40;
  margin: 0;
  line-height: 1;
  padding: 20px 0;
}

.zc-panel-header-sub-title strong {
  display: block;
  font-weight: inherit;
}

.zc-panel-header-sub-title small {
  display: block;
  color: #999;
  margin-top: 5px;
}

.zc-panel-header-operate {
}

.zc-panel-header-operate a {
  display: inline-flex;
  margin-left: 20px;
  color: var(--theme-color);
}

.zc-panel-header-operate a.icon-more {
  color: #666;
}

.zc-panel-container {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.zc-panel-container-row {
  flex-direction: row;
}

.zc-panel-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  width: 100%;
}

.zc-panel-empty strong {
  display: block;
  font-size: 15pt;
  line-height: 1.5em;
  font-weight: 400;
  color: #333;
}

.zc-panel-empty small {
  display: block;
  font-size: 10.5pt;
  line-height: 1.5em;
  color: #888;
}

.button-block {
  display: inline-flex;
  background: var(--theme-color);
  color: #fff !important;
  font-size: 9pt;
  height: 25px;
  line-height: 0.9;
  box-sizing: border-box;
  border-radius: 12.5px;
  justify-content: center;
  align-items: center;
  padding: 0 12.5px;
  text-decoration: none;
  cursor: pointer;
}

.button-solid {
  display: inline-flex;
  border: solid 1px var(--theme-color);
  background: #fff;
  color: var(--theme-color) !important;
  font-size: 9pt;
  height: 25px;
  line-height: 0.9;
  box-sizing: border-box;
  border-radius: 12.5px;
  justify-content: center;
  align-items: center;
  padding: 0 12.5px;
  text-decoration: none;
  cursor: pointer;
}

/* .button-block.button-yellow {
  background: linear-gradient(140deg, #ffaf3f 0%, #f9bb34 100%);
}

.button-solid.button-yellow {
  color: #ffaf3f !important;
  border-color: #ffaf3f;
} */

.button-block:hover,
.button-solid:hover {
  text-decoration: none;
}

.button-big {
  height: 35px;
  border-radius: 17.5px;
  padding: 0 17.5px;
  font-size: 10.5pt;
}

.zc-kv {
  margin-bottom: 50px;
}

.zc-kv:last-child {
  margin-bottom: 20px;
}

.zc-kv-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.zc-kv-header-title {
  font-size: 12pt;
  font-weight: 400;
  color: #3c3e40;
  margin: 0;
  line-height: 1;
}

.zc-kv-header-title sup {
  background-color: #fed8cc;
  color: var(--theme-color);
  position: relative;
  display: inline-block;
  line-height: 1;
  padding: 5px 10px;
  border-radius: 3px;
  right: -10px;
  top: 0;
}

.zc-kv-header-title strong {
  display: block;
  font-weight: inherit;
}

.zc-kv-header-title small {
  display: block;
  color: #999;
  margin-top: 5px;
}

.zc-kv-header-operate {
}

.zc-kv-header-operate > * {
  margin-left: 10px;
}

.zc-kv-container {
  list-style: none;
}

.zc-kv-container li {
  margin-top: 15px;
}

.zc-kv-container li strong {
  display: inline-block;
  width: 80px;
  font-size: 9pt;
  font-weight: normal;
  color: #999;
}

.zc-kv-container li small {
  font-size: 10.5pt;
}

.zc-table {
  font-size: 10.5pt;
  text-align: center;
  border-collapse: collapse;
  color: #666;
}

.zc-table thead td,
.zc-table thead th {
  border-bottom: solid 3px #eee;
}

.zc-table tbody tr td,
.zc-table tbody th {
  border-bottom: solid 1px #eee;
}

.zc-table th {
  font-weight: 400;
  font-size: 10.5pt;
  padding: 15px 5px;
}

.zc-table td {
  font-weight: 400;
  font-size: 10.5pt;
  padding: 15px 5px;
}

.zc-table strong {
  font-size: 10.5pt;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #333;
}
.zc-table small {
  display: block;
  color: #999;
}

.zc-table i {
  color: var(--theme-color);
  font-weight: bold;
}

.zc-table-title {
  font-size: 10.5pt;
  text-align: center;
  border-collapse: collapse;
  color: #666;
  width: 100%;
  table-layout: fixed;
}

.zc-table-item {
  padding-top: 20px;
}

.zc-table-spliter {
  margin: 5px;
  color: #999;
  display: flex;
  justify-content: space-between;
}

.zc-table-content {
  font-size: 9pt;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.zc-table-content td,
.zc-table-content th {
  padding: 5px;
  border: 1px solid #e3e3e3;
}

.zc-table-content ul {
  list-style: none;
}

.zc-table-content-operate > * ~ * {
  margin-left: 5px;
}

.zc-figure-text {
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.1;
}

.zc-figure-image {
  width: 50px;
  height: 50px;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;
  object-fit: cover;
  position: relative;
}

.zc-tr-disabled .zc-figure-image:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  right: 0;
}

.zc-figure-image img {
  width: 50px;
  height: 50px;
  display: block;
}

.zc-figure-text h3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 10.5pt;
  font-weight: 400;
  line-height: 1;
}

.zc-figure-text strong {
  font-weight: normal;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.zc-tr-disabled h3:after {
  content: "商品已失效";
  font-size: 9pt;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 4px;
  display: block;
  padding: 7px 10px;
  margin-top: 10px;
  align-self: flex-start;
}

.zc-tr-disabled h3 {
  opacity: 0.5;
}

.zc-figure-text small {
  margin-top: 5px;
}

.zc-paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.zc-paragraph h2 {
  font-weight: 400;
  font-size: 21pt;
}

.zc-paragraph p {
  font-size: 10.5pt;
  color: #999;
}

.zc-paragraph i {
  color: var(--theme-color);
  font-weight: bold;
}

.zc-text-delete {
  text-decoration: line-through;
  color: #999;
}

.zc-text i {
  color: var(--theme-color);
  font-weight: bold;
}

.goods-list {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden !important;
  zoom: 1 !important;
  padding-top: 20px;
}

.zc-filter {
  border: solid 1px #e4e4e4;
  padding: 5px 15px;
  display: flex;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}
.zc-filter span {
  color: #999;
  display: block;
  margin-right: 20px;
  flex-shrink: 0;
  padding-top: 5px;
}

.zc-filter ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 10.5pt;
}

.zc-filter li {
  margin: 0;
  padding: 0;
}

.zc-filter a {
  display: block;
  padding: 5px 10px;
}

.zc-sub-filter {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.zc-sub-filter ul {
  display: flex;
  list-style: none;
  padding: 0 6px;
  font-size: 10pt;
}

.zc-sub-filter ul li {
  padding: 5px 10px;
  cursor: pointer;
  color: #666;
}

.zc-sub-filter ul li.active {
  color: var(--theme-color);
}

.zc-sub-search {
  display: flex;
  border: solid 1px #ddd;
  background-color: #f9f9f9;
  border-radius: 3px;
  overflow: hidden;
}

.zc-sub-search input {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 5px 10px;
  width: 250px;
}

.zc-sub-search img {
  display: block;
  width: 16px;
  height: 16px;
  padding: 5px;
  cursor: pointer;
  background-color: #f3f3f3;
  border-left: solid 1px #ddd;
}

.zc-sub-filter .zc-filter-order {
  position: relative;
  padding: 5px 17px 5px 10px;
}

.zc-filter-order:before {
  position: absolute;
  right: 5px;
  top: 3px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px;
  border-color: transparent transparent #ccc transparent;
}

.zc-filter-order.active.asc:before {
  border-bottom-color: var(--theme-color);
}

.zc-filter-order:after {
  position: absolute;
  right: 5px;
  bottom: 3px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px;
  border-color: #ccc transparent transparent transparent;
}

.zc-filter-order.active.desc:after {
  border-top-color: var(--theme-color);
}
</style>
