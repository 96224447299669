<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-29 17:24:21
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <mainHeader />
    <div class="py-container">
      <div class="zc-panel">
        <template v-if="!brandCartGoods || !brandCartGoods.length">
          <div class="zc-panel-container">
            <p class="zc-panel-empty">
              <img :src="require('@/assets/images/n2.png')" />
              <strong>暂无商品</strong>
              <small><a @click="$router.push({ path: '/home' })">去添加点什么吧</a></small>
            </p>
          </div>
        </template>
        <template v-else>
          <div class="zc-panel-header">
            <h3 class="zc-panel-header-title">全部商品</h3>
          </div>
          <div class="zc-panel-container">
            <table class="zc-table">
              <colgroup>
                <col style="width:30px;" />
                <col style="width:90px" />
                <col style="width:auto" />
                <col style="width:120px" />
                <col style="width:160px" />
                <col style="width:120px" />
              </colgroup>
              <thead>
                <tr>
                  <th style="text-align:left;">
                    <!-- <input type="checkbox" /> -->
                  </th>
                  <th colspan="2">
                    <!-- <span style="float:left;">全选</span> -->
                    商品
                  </th>
                  <!-- <th>单价（元）</th> -->
                  <th>数量</th>
                  <th>小计（元）</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody v-for="(bitem, index) in brandCartGoods" :key="index">
                <tr v-for="(item, index2) in bitem.cartList" :key="index2" :class="'item ' + (!isEditCart ? 'edit' : '')">
                  <td style="text-align:left;" @click="checkedItem" :data-productid="item.productId">
                    <input type="checkbox" :disabled="item.isExist == 0" :checked="item.checked" />
                  </td>
                  <td>
                    <div class="isExistClass1">
                      <img :src="item.picUrl" />
                      <div class="isExistClass2" v-if="item.isExist == 0"></div>
                    </div>
                  </td>
                  <td style="text-align:left;">
                    <strong @click="goodsItemBnt(item)" :class="{ isExistClass: item.isExist == 0 }" style="width:500px;">{{ item.goodsName }}</strong>
                    <small>
                      <span>规格:</span>
                      <span v-for="(item2, index2) in item.specifications" :key="index2">{{ item2 }}</span>
                    </small>
                    <span v-if="item.isExist == 0" class="isExistClass3">商品已失效</span>
                  </td>
                  <!-- <td></td> -->
                  <td>
                    <!-- <inputCounter min="1" /> -->
                    <inputCounter min="1" :max="item.goodsNumber || 200" :value="item.number" @change="onNumberChange($event, item.productId)" :tip-max="item.goodsNumber > 200 ? '购买商品数量不能大于${value}' : '购买商品数量不能大于库存${value}'" :wait="item.$request" v-if="item.isExist != 0" />
                    <!-- <span class="cut" @click="cutNumber" :data-productid="item.productId">-</span>
									<input :value="item.number" class="number" disabled="true" type="number" />
									<span class="add" @click="addNumber" :data-productid="item.productId">+</span> -->
                  </td>
                  <td>
                    <i v-if="item.isExist != 0">￥{{ Number(item.price * item.number).toFixed(2) }}</i>
                  </td>
                  <td>
                    <a @click="deleteItem(item)">删除</a>
                    <span v-hasPermi="['user:order:collect']">
                      |
                      <a @click="addCollectOrNot(item)">移到收藏</a>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="text-align:left;">
                  <td @click="checkedAll">
                    <input type="checkbox" :checked="checkedAllStatus" />
                  </td>
                  <td>全选</td>
                  <td>
                    <a @click="deleteCart">删除选中的商品</a>
                    <!-- |
                  <a>移到我的关注</a> -->
                  </td>
                  <td colspan="3">
                    <div style="display:flex;justify-content:space-between;align-items:center;">
                      <span>
                        已选商品
                        <i>{{ cartTotal.checkedGoodsCount }}</i>
                      </span>
                      <span>
                        总价（不含运费）：
                        <i>¥{{ cartTotal.checkedGoodsAmount }}</i>
                      </span>
                      <a class="button-block button-big" @click="checkoutOrder" style="width:100px;">结算</a>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </template>
      </div>
      <div class="zc-panel" v-if="hots && hots.length">
        <div class="zc-panel-header">
          <h3 class="zc-panel-header-title">为您推荐</h3>
        </div>
        <div class="zc-panel-container">
          <div class="zc-panel-container zc-panel-container-row">
            <template v-for="(item, index) in hots">
              <productCard class="yui3-u-1-4" :url="`/product/detail/${item.id}`" :thumb="item.picUrl" :title="item.name" :price="item.retailPrice" :key="index" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

import commonSlider from "@/pc/components/slider";
import productCard from "@/pc/components/productCard";
import inputCounter from "@/pc/components/inputCounter";

export default {
  name: "userCart",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    commonSlider: commonSlider,
    productCard: productCard,
    inputCounter: inputCounter,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  data() {
    return {
      isMultiOrderModel: 0,
      cartGoods: [],
      brandCartGoods: [],
      cartTotal: {
        goodsCount: 0,
        goodsAmount: 0.0,
        checkedGoodsCount: 0,
        checkedGoodsAmount: 0.0,
      },
      isEditCart: false,
      checkedAllStatus: true,
      editCartList: [],
      hasLogin: false,
      hots: [],
    };
  },
  created() {
    this.getHotList();
  },
  mounted: function() {
    // 页面显示
    if (localStorage.token) {
      this.getCartList();
    }

    this.setData({
      hasLogin: localStorage.token ? true : false,
    });
  },
  watch: {
    brandCartGoods() {
      // this.$store.dispatch("onCartNum", this.brandCartGoods.length);
    },
  },
  methods: {
    getHotList() {
      this.$api.base.HotGoods({ size: 4 }).then((response) => {
        this.hots = response.hotGoodsList.map((item) => {
          item.goods.retailPrice = Math.min(...item.products.map((i) => i.price));
          return item.goods;
        });
      });
    },
    deleteItem(i) {
      let that = this;
      this.$api.base
        .CartDelete({
          productIds: [i.productId],
        })
        .then(function(res) {
          if (res.isMultiOrderModel === 1) {
            that.setData({
              isMultiOrderModel: res.isMultiOrderModel,
              brandCartGoods: res.brandCartGoods,
              cartTotal: res.cartTotal,
            });
          } else {
            that.setData({
              isMultiOrderModel: res.isMultiOrderModel,
              cartGoods: res.cartList,
              cartTotal: res.cartTotal,
            });
          }

          that.setData({
            checkedAllStatus: that.isCheckedAll(),
          });
        });
    },
    //添加或是取消收藏
    addCollectOrNot: function(i) {
      this.$confirm('<div class="zc-message-tip"><i class="el-icon-info"></i><h2>移入收藏</h2><p>移动后选中商品将不在购物车中显示</p></div>', "", {
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false,
      })
        .then(() => {
          let that = this;

          this.$api.base
            .CollectAdd({
              type: 0,
              valueId: i.goodsId,
            })
            .then(function(res) {
              that.deleteItem(i);
            });
        })
        .catch(() => {});
    },
    goodsItemBnt(it) {
      uni.navigateTo({
        url: "/product/detail/" + it.goodsId,
      });
    },
    goLogin() {
      uni.navigateTo({
        url: "/user/login",
      });
    },

    getCartList: function() {
      let that = this;
      this.$api.base.CartList().then(function(res) {
        if (res.isMultiOrderModel === 1) {
          that.setData({
            isMultiOrderModel: res.isMultiOrderModel,
            brandCartGoods: res.brandCartGoods,
            cartTotal: res.cartTotal,
          });
        } else {
          that.setData({
            isMultiOrderModel: res.isMultiOrderModel,
            cartGoods: res.cartList,
            cartTotal: res.cartTotal,
          });
        }

        that.setData({
          checkedAllStatus: that.isCheckedAll(),
        });
      });
    },
    isChildCheckedAll: function(cartList) {
      return cartList.every(function(element, index, array) {
        if (element.checked == true) {
          return true;
        } else {
          return false;
        }
      });
    },
    isCheckedAll: function() {
      let that = this;

      if (that.isMultiOrderModel === 1) {
        //多店铺模式的商品全选判断
        return that.brandCartGoods.every(function(element, index, array) {
          if (that.isChildCheckedAll(element.cartList)) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        //判断购物车商品已全选
        return that.cartGoods.every(function(element, index, array) {
          if (element.checked == true) {
            return true;
          } else {
            return false;
          }
        });
      }
    },
    doCheckedAll: function() {
      let checkedAll = this.isCheckedAll();
      this.setData({
        checkedAllStatus: this.isCheckedAll(),
      });
    },
    getProductChecked: function(productId) {
      let that = this;
      let isChecked = null;

      if (that.isMultiOrderModel === 1) {
        that.brandCartGoods.forEach(function(v) {
          let cartList = v.cartList;
          cartList.forEach(function(o) {
            if (o.productId == productId) {
              isChecked = o.checked ? 0 : 1;
            }
          });
        });
      } else {
        that.cartGoods.forEach(function(o) {
          if (o.productId === productId) {
            isChecked = o.checked ? 0 : 1;
          }
        });
      }

      return isChecked;
    },
    checkedItem: function(event) {
      //let itemIndex = event.target.dataset.itemIndex;
      let productId = event.currentTarget.dataset.productid;
      let that = this;
      let productIds = [];
      productIds.push(productId);
      let isChecked = that.getProductChecked(productId);

      if (!this.isEditCart) {
        this.$api.base
          .CartChecked({
            productIds: productIds,
            isChecked: isChecked,
          })
          .then(function(res) {
            if (res.isMultiOrderModel === 1) {
              that.setData({
                isMultiOrderModel: res.isMultiOrderModel,
                brandCartGoods: res.brandCartGoods,
                cartTotal: res.cartTotal,
              });
            } else {
              that.setData({
                isMultiOrderModel: res.isMultiOrderModel,
                cartGoods: res.cartList,
                cartTotal: res.cartTotal,
              });
            }

            that.setData({
              checkedAllStatus: that.isCheckedAll(),
            });
          });
      } else {
        //编辑状态
        if (that.isMultiOrderModel === 1) {
          let tmpBrandCartData = this.brandCartGoods.map(function(element, index, array) {
            let tmpBrandGoods = element.cartList.map(function(childEle, childIndex, childArr) {
              if (childEle.productId === productId) {
                childEle.checked = !childEle.checked;
              }

              return childEle;
            });
            element.cartList = tmpBrandGoods;
            return element;
          });
          that.setData({
            brandCartGoods: tmpBrandCartData,
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });
        } else {
          let tmpCartData = this.cartGoods.map(function(element, index, array) {
            if (element.productId === productId) {
              element.checked = !element.checked;
            }

            return element;
          });
          that.setData({
            cartGoods: tmpCartData,
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });
        }
      }
    },
    getCheckedGoodsCount: function() {
      let that = this;
      let checkedGoodsCount = 0;

      if (that.isMultiOrderModel === 1) {
        that.brandCartGoods.forEach(function(v) {
          v.cartList.forEach(function(o) {
            if (o.checked === true) {
              checkedGoodsCount += o.number;
            }
          });
        });
      } else {
        that.cartGoods.forEach(function(v) {
          if (v.checked === true) {
            checkedGoodsCount += v.number;
          }
        });
      }

      return checkedGoodsCount;
    },
    checkedAll: function() {
      let that = this;

      if (!this.isEditCart) {
        let productIds = [];

        if (that.isMultiOrderModel === 1) {
          that.brandCartGoods.forEach(function(v) {
            v.cartList.forEach(function(o) {
              productIds.push(o.productId);
            });
          });
        } else {
          let productIds = that.cartGoods.map(function(v) {
            return v.productId;
          });
        }

        this.$api.base
          .CartChecked({
            productIds: productIds,
            isChecked: that.isCheckedAll() ? 0 : 1,
          })
          .then(function(res) {
            if (res.isMultiOrderModel === 1) {
              that.setData({
                isMultiOrderModel: res.isMultiOrderModel,
                brandCartGoods: res.brandCartGoods,
                cartTotal: res.cartTotal,
              });
            } else {
              that.setData({
                isMultiOrderModel: res.isMultiOrderModel,
                cartGoods: res.cartList,
                cartTotal: res.cartTotal,
              });
            }

            that.setData({
              checkedAllStatus: that.isCheckedAll(),
            });
          });
      } else {
        //编辑状态,将所有
        let checkedAllStatus = that.isCheckedAll();

        if (that.isMultiOrderModel === 1) {
          let tmpBrandCartData = this.brandCartGoods.map(function(element, index, array) {
            let tmpBrandGoods = element.cartList.map(function(childEle, childIndex, childArr) {
              childEle.checked = !checkedAllStatus;
              return childEle;
            });
            element.cartList = tmpBrandGoods;
            return element;
          });
          that.setData({
            brandCartGoods: tmpBrandCartData,
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });
        } else {
          let tmpCartData = this.cartGoods.map(function(element, index, array) {
            element.checked = !checkedAllStatus;
            return element;
          });
          that.setData({
            cartGoods: tmpCartData,
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });
        }
      }
    },
    editCart: function() {
      var that = this;

      if (this.isEditCart) {
        this.getCartList();
        this.setData({
          isEditCart: !this.isEditCart,
        });
      } else {
        //编辑状态
        if (that.isMultiOrderModel === 1) {
          let tmpBrandCartData = that.brandCartGoods.map(function(element, index, array) {
            let tmpBrandGoods = element.cartList.map(function(childEle, childIndex, childArr) {
              childEle.checked = false;
              return childEle;
            });
            element.cartList = tmpBrandGoods;
            return element;
          });
          that.setData({
            brandCartGoods: tmpBrandCartData,
            isEditCart: !that.isEditCart,
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });
        } else {
          let tmpCartData = that.cartGoods.map(function(element, index, array) {
            element.checked = false;
            return element;
          });
          that.setData({
            // editCartList: this.data.cartGoods,
            cartGoods: tmpCartData,
            isEditCart: !that.isEditCart,
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });
        }
      }
    },
    updateCart: function(productId, goodsId, number, id, event, callback) {
      let that = this;
      this.$api.base
        .CartUpdate({
          productId: productId,
          goodsId: goodsId,
          number: number,
          id: id,
        })
        .then(function(res) {
          if (event) {
            let productId = event.target.dataset.productid;
            let cartItem = that.getProductItem(productId);
            let number = cartItem.number + 1;
            cartItem.number = number;
          }

          that.setData({
            checkedAllStatus: that.isCheckedAll(),
            "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
          });

          callback && callback();

          that.getCartList();
        })
        .catch((err) => {
          this.getCartList();
        });
    },
    getProductItem: function(productId) {
      let that = this;
      let productItem = null;

      if (that.isMultiOrderModel === 1) {
        that.brandCartGoods.forEach(function(v) {
          let cartList = v.cartList;
          cartList.forEach(function(o) {
            if (o.productId == productId) {
              productItem = o;
            }
          });
        });
      } else {
        that.cartGoods.forEach(function(o) {
          if (o.productId == productId) {
            productItem = o;
          }
        });
      }

      return productItem;
    },
    setProductItem: function(cartItem, productId) {
      let that = this;

      if (that.isMultiOrderModel === 1) {
        let tmpBrandCartData = this.brandCartGoods.map(function(element, index, array) {
          let tmpBrandGoods = element.cartList.map(function(childEle, childIndex, childArr) {
            if (childEle.productId === productId) {
              return cartItem;
            } else {
              return childEle;
            }
          });
          element.cartList = tmpBrandGoods;
          return element;
        });
        that.setData({
          brandCartGoods: tmpBrandCartData,
          checkedAllStatus: that.isCheckedAll(),
          "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
        });
      } else {
        let tmpCartData = this.cartGoods.map(function(element, index, array) {
          if (element.productId === productId) {
            return cartItem;
          } else {
            return element;
          }
        });
        that.setData({
          cartGoods: tmpCartData,
          checkedAllStatus: that.isCheckedAll(),
          "cartTotal.checkedGoodsCount": that.getCheckedGoodsCount(),
        });
      }
    },
    onNumberChange(event, productId) {
      let cartItem = this.getProductItem(productId);

      if (cartItem.$request) return;

      cartItem.$request = true;
      this.$forceUpdate();

      console.log(event);

      this.updateCart(cartItem.productId, cartItem.goodsId, event, cartItem.id, null, () => {
        cartItem.number = event;
        this.setProductItem(cartItem, productId);

        setTimeout(() => (cartItem.$request = false));
      });
    },
    cutNumber: function(event) {
      //let itemIndex = event.target.dataset.itemIndex;
      let productId = event.currentTarget.dataset.productid;
      let cartItem = this.getProductItem(productId);
      let number = cartItem.number - 1 > 1 ? cartItem.number - 1 : 1;
      cartItem.number = number;
      this.setProductItem(cartItem, productId);
      this.updateCart(cartItem.productId, cartItem.goodsId, number, cartItem.id);
    },
    addNumber: function(event) {
      // let itemIndex = event.target.dataset.itemIndex;
      let productId = event.currentTarget.dataset.productid;
      let cartItem = this.getProductItem(productId);
      let number = cartItem.number + 1;
      // cartItem.number = number
      this.setProductItem(cartItem, productId);
      this.updateCart(cartItem.productId, cartItem.goodsId, number, cartItem.id, event);
    },
    checkoutOrder: function() {
      //获取已选择的商品
      let that = this;

      let flag = [];
      that.brandCartGoods.forEach(function(v) {
        v.cartList.forEach(function(o) {
          if (o.checked === true && o.isExist == 0) {
            flag.push(o);
          }
        });
      });
      if (flag.length) {
        uni.showToast({
          title: "存在已失效的商品，无法结算",
        });
        return;
      }

      /*var checkedGoods = this.data.cartGoods.filter(function(element, index, array) {
						  if (element.checked == true) {
							return true;
						  } else {
							return false;
						  }
						});
						if (checkedGoods.length <= 0) {
						  return false;
						}*/

      if (that.getCheckedGoodsCount() <= 0) {
        uni.showModal({
          title: "错误信息",
          content: "请勾选需要下单的商品！",
          showCancel: false,
        });
        return false;
      } // storage中设置了cartId，则是购物车购买

      try {
        uni.setStorageSync("cartId", 0);
        uni.setStorageSync("seckilled", 0);
        uni.navigateTo({
          url: "/order/confirm",
        });
      } catch (e) {}
    },
    deleteCart: function() {
      //获取已选择的商品
      let that = this;
      /*let productIds = this.data.cartGoods.filter(function(element, index, array) {
						  if (element.checked == true) {
							return true;
						  } else {
							return false;
						  }
						});
						  if (productIds.length <= 0) {
						  return false;
						}*/

      if (that.getCheckedGoodsCount() <= 0) {
        uni.showModal({
          title: "错误信息",
          content: "请勾选需要删除的商品！",
          showCancel: false,
        });
        return false;
      }

      let productIds = [];

      if (that.isMultiOrderModel === 1) {
        that.brandCartGoods.forEach(function(v) {
          v.cartList.forEach(function(o) {
            if (o.checked == true) {
              productIds.push(o.productId);
            }
          });
        });
      } else {
        productIds = that.cartGoods.map(function(v) {
          if (v.checked == true) {
            return v.productId;
          }
        });
      }

      this.$api.base
        .CartDelete({
          productIds: productIds,
        })
        .then(function(res) {
          if (res.isMultiOrderModel === 1) {
            that.setData({
              isMultiOrderModel: res.isMultiOrderModel,
              brandCartGoods: res.brandCartGoods,
              cartTotal: res.cartTotal,
            });
          } else {
            that.setData({
              isMultiOrderModel: res.isMultiOrderModel,
              cartGoods: res.cartList,
              cartTotal: res.cartTotal,
            });
          }

          that.setData({
            checkedAllStatus: that.isCheckedAll(),
          });
        });
    },
  },
};
</script>

<style scoped>
.noGoodsList {
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
}
.isExistClass {
  color: #c5c5c5;
}
.isExistClass1 {
  position: relative;
}
.isExistClass2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
}
.isExistClass3 {
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 10px;
  background: #c5c5c5;
  color: #fff;
  margin-top: 10px;
  display: inline-block;
}
</style>
