<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-10-10 14:07:13
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <mainHeader />
    <div class="py-container">
      <userMenu />
      <div class="yui3-u-5-6 zc-main">
        <div class="zc-panel">
          <div style="padding-bottom: 20px">
            <span class="selectTitle">订单状态</span>
            <el-select v-model="optionsValue" @change="handleChange1" placeholder="请选择">
              <el-option v-for="item in optionsList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>

            <span class="selectTitle">公司</span>
            <div style="display: inline-block">
              <el-cascader :props="{ checkStrictly: true }" v-model="optionsValue1" :options="optionsList1" @change="handleChange"></el-cascader>
            </div>

            <span class="selectBtn1 button-solid" @click="resetSearch" style="margin-left: 30px">重置</span>
            <span class="selectBtn1 button-solid" @click="resetSearch1">查询</span>
          </div>
          <template v-if="orders && orders.length">
            <table class="zc-table-title">
              <colgroup>
                <col style="width:auto;" />
                <col style="width:80px;" />
                <col style="width:80px" />
                <col style="width:100px" />
                <col style="width:120px" />
                <col style="width:120px" />
                <col style="width:140px" />
              </colgroup>
              <thead>
                <tr>
                  <th>宝贝</th>
                  <th>单价</th>
                  <th>数量</th>
                  <th>收货人</th>
                  <th>实付款</th>
                  <th>交易状态</th>
                  <th>订单操作</th>
                </tr>
              </thead>
            </table>
            <template v-for="(order, i) in orders">
              <div class="zc-table-item" :key="i">
                <div class="zc-table-spliter">
                  <span>订单编号：{{ order.orderSn }}</span>
                  <span>{{ order.orderStatusText }}</span>
                  <span>{{ order.addTime }}</span>
                  <span>{{ order.department.pid == 0 ? "总" : "子" }}公司：{{ order.department.name }}</span>
                  <span v-if="order.user">账号：{{ order.user.username }}</span>
                </div>
                <table class="zc-table-content">
                  <colgroup>
                    <col style="width:auto;" />
                    <col style="width:80px;" />
                    <col style="width:80px" />
                    <col style="width:100px" />
                    <col style="width:120px" />
                    <col style="width:120px" />
                    <col style="width:140px" />
                  </colgroup>
                  <tbody>
                    <tr v-for="(goods, m) in order.goodsList" :key="m" :temp="(span = order.goodsList.length)" :class="{ 'zc-tr-disabled': !goods.isExist }">
                      <td>
                        <div class="zc-figure-text">
                          <div class="zc-figure-image">
                            <img :src="goods.picUrl" />
                          </div>
                          <h3>
                            <strong>
                              <a @click="$router.push({ path: `/product/detail/${goods.goodsId}` })" v-if="goods.isExist">{{ goods.goodsName }}</a>
                              <span v-else>{{ goods.goodsName }}</span>
                            </strong>
                            <small>规格：{{ goods.specifications.join(" ") }}</small>
                            <small v-if="goods.refundStatus || goods.refundStatus == 0">售后状态：{{ refundStatus1[goods.refundStatus] }}</small>
                            <a style="margin: 5px 0px 0;color: var(--theme-color);font-size: 12px;" @click="onRefundDetail(goods.refundId)" v-if="goods.refundId">退货查看</a>
                          </h3>
                        </div>
                      </td>
                      <td>
                        <ul>
                          <!-- <li class="zc-text-delete">¥{{goods.price}}</li> -->
                          <li class="color1">¥{{ goods.price }}</li>
                        </ul>
                      </td>
                      <td class="color1">x{{ goods.number }}</td>
                      <td :rowspan="span" v-if="m == 0">
                        <span class="color1">{{ order.consignee }}</span>
                        <!-- <a style="margin:5px 0" class="button-solid button-yellow" v-if="order.handleOption.rebuy && goods.isExist" @click="onOrderAgain(goods.goodsId)">再次购买</a> -->

                        <!-- <span v-if="!(order.handleOption.rebuy && goods.isExist) && !goods.refundId">-</span> -->
                      </td>
                      <td :rowspan="span" v-if="m == 0">
                        <ul>
                          <li class="color1">¥{{ order.actualPrice }}</li>
                          <!-- <li>（含运费：￥{{ order.freightPrice }}）</li> -->
                        </ul>
                      </td>
                      <td :rowspan="goods.rowspan || ''" v-if="goods.rowspan">
                        <ul>
                          <li class="color1">{{ goods.orderStatusText || order.orderStatusText }}</li>
                        </ul>
                      </td>
                      <td :rowspan="span" v-if="m == 0" class="zc-table-content-operate">
                        <a class="button-text" @click="$router.push({ path: `/user/order/detail/${order.id}?shipId=${goods.shipId || ''}` })">订单详情</a>
                        <a class="button-text" @click="lookBtn1(order)" v-if="['已收货', '已收货(系统)'].includes(order.orderStatusText) && settleStatusDict[order.settleStatus] != '待开票' && settleStatusDict[order.settleStatus] != '待审核'" v-hasPermi="['user:order:settleDetail']">查看发票</a>
                        <template v-if="order.user.id == user.id">
                          <a
                            class="button-text"
                            @click="$router.push({ path: `/user/order/refund/${order.id}?shipId=${goods.shipId || ''}` })"
                            v-if="!['未付款', '已退款', '订单取消，退款中', '已取消', '已取消(系统)'].includes(order.orderStatusText) && !['待审核', '待结算', '已结算'].includes(settleStatusDict[order.settleStatus])"
                          >
                            退款申请
                          </a>
                          <a class="button-text" @click="onOrderCancel(order)" v-if="order.handleOption.cancel">取消</a>
                          <!-- <a class="button-text" @click="onOrderDelete(order)" v-if="order.handleOption.delete">删除</a> -->
                          <a class="button-text" @click="onOrderPay(order)" v-if="order.handleOption.pay">付款</a>
                          <a class="button-text" @click="onOrderConfirm(order)" v-if="order.flag && order.handleOption.confirm">
                            确认收货
                          </a>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <commonPagination :total="query.total" :size="query.size" :load="getOrderList" v-if="query.total > query.size"></commonPagination>
          </template>
          <p class="zc-panel-empty" v-else>
            <img :src="require('@/assets/images/n2.png')" />
            <strong>暂无该类订单</strong>
            <small><a @click="$router.push({ path: '/home' })">继续逛逛吧</a></small>
          </p>
        </div>
        <div class="zc-panel" v-if="hots && hots.length">
          <div class="zc-panel-header">
            <h3 class="zc-panel-header-title">为您推荐</h3>
          </div>
          <div class="zc-panel-container zc-panel-container-row">
            <template v-for="(item, index) in hots">
              <productCard class="yui3-u-1-4" :url="`/product/detail/${item.id}`" :thumb="item.picUrl" :title="item.name" :price="item.retailPrice" :key="index" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <mainFooter />

    <el-dialog title="退款详情" :visible.sync="dialog.refund" :close-on-click-modal="false" :close-on-press-escape="false" width="400px" class="zc-dialog  zc-dialog-detail">
      <el-form label-position="left" label-width="100px" size="mini">
        <el-form-item label="退单编号">
          <span>{{ form.refund.refundSn }}</span>
        </el-form-item>
        <el-form-item label="申请时间">
          <span>{{ form.refund.addTime }}</span>
        </el-form-item>
        <el-form-item label="退款状态">
          <span>{{ form.refund.status == 0 ? "待供应商操作" : form.refund.status == 2 ? "审批拒绝" : "审批通过" }}</span>
        </el-form-item>
        <el-form-item label="拒绝原因" v-if="form.refund.status == 2">
          <span>{{ form.refund.approveMsg }}</span>
        </el-form-item>
        <el-form-item label="申请总额">
          <span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
          <span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
        </el-form-item>
        <el-form-item label="审批总额" v-if="form.refund.status == 1 || form.refund.status == 3">
          <span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
          <span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
        </el-form-item>
        <el-form-item label="原订单编号">
          <span>{{ form.refund.orderSn }}</span>
        </el-form-item>
        <el-form-item label="收货人">
          <span>{{ form.refund.consignee }}</span>
        </el-form-item>
        <el-form-item label="联系方式">
          <span>{{ form.refund.mobile }}</span>
        </el-form-item>
      </el-form>
      <img style="position:absolute;right:10px;bottom:30px;" :src="form.refund.status == 0 ? require('../../../assets/images/wait.png') : form.refund.status == 2 ? require('../../../assets/images/reject.png') : require('../../../assets/images/pass.png')" />
    </el-dialog>

    <el-dialog title="发票详情" :visible.sync="dialog1" :close-on-click-modal="false" :close-on-press-escape="false" width="800px">
      <el-form label-position="left" label-width="100px" size="mini">
        <el-form-item label="发票状态">
          <span>{{ settleStatusDict[settleDetail1.settleStatus] }}</span>
        </el-form-item>
        <el-form-item label="发票">
          <el-carousel :autoplay="false" height="300px" style="background: #f7f7f7" v-if="settleDetail1.invoiceImg">
            <el-carousel-item style="text-align:center" v-for="(item, index) in settleDetail1.invoiceImg" :key="index">
              <img style="height: 300px;" :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
          <span v-else>-</span>
        </el-form-item>
        <el-form-item label="发票号码">
          <span>{{ settleDetail1.invoiceNo || "-" }}</span>
        </el-form-item>
        <el-form-item label="物流公司">
          <span>{{ settleDetail1.shipChannel || "-" }}</span>
        </el-form-item>
        <el-form-item label="物流单号">
          <span>{{ settleDetail1.shipSn || "-" }}</span>
        </el-form-item>
        <el-form-item label="物流信息" v-if="settleDetail1.expressData && settleDetail1.expressData.traces && settleDetail1.expressData.traces.length">
          <el-timeline>
            <el-timeline-item v-for="(activity, index) in settleDetail1.expressData.traces" :key="index" :timestamp="activity.acceptTime">
              {{ activity.acceptStation }}
            </el-timeline-item>
          </el-timeline>
        </el-form-item>
        <el-form-item label="结算凭证">
          <el-carousel :autoplay="false" height="300px" style="background: #f7f7f7" v-if="settleDetail1.settleImg">
            <el-carousel-item style="text-align:center" v-for="(item, index) in settleDetail1.settleImg" :key="index + 'a'">
              <img style="height: 300px;" :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
          <span v-else>-</span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import commonPagination from "@/pc/components/pagination";
import userMenu from "@/pc/components/userMenu";
import productCard from "@/pc/components/productCard";

export default {
  name: "userOrder",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    userMenu: userMenu,
    productCard: productCard,
    commonPagination: commonPagination,
  },
  data() {
    let showTypes = {
      all: 0,
      pay: 1,
      send: 2,
      receive: 3,
      service: 4,
      complete: 5,
    };

    return {
      orderDict: {
        0: "已付款",
        1: "已发货",
        2: "已收货",
        3: "退款中",
        4: "完成退款",
      },
      refundStatus1: {
        0: "待供应商操作",
        2: "审批拒绝",
        3: "审批通过",
      },
      settleStatusDict: {
        1: "待开票",
        2: "待审核",
        3: "待结算",
        4: "已结算",
      },
      dialog1: false,
      optionsList1: [],
      optionsValue1: "",
      optionsList: [
        {
          value: "0",
          label: "全部",
        },
        // {
        //   value: "1",
        //   label: "代发货",
        // },
        {
          value: "2",
          label: "待发货",
        },
        {
          value: "3",
          label: "待收货",
        },
        {
          value: "4",
          label: "售后中",
        },
        {
          value: "5",
          label: "已完成",
        },
        {
          value: "6",
          label: "待开票",
        },
        {
          value: "7",
          label: "已开票",
        },
        {
          value: "8",
          label: "待结算",
        },
        {
          value: "9",
          label: "已结算",
        },
      ],
      optionsValue: "0",
      showTypes: showTypes,
      query: {
        showType: "0",
        departmentId: null,
        size: 5,
        page: 1,
        total: 0,
      },
      orders: [],
      dialog: {
        refund: false,
      },
      form: {
        refund: {},
      },
      hots: [],
      settleDetail1: {},
      departmentId: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
    dynamicOrderRefund() {
      return this.$store.getters.dynamicOrderRefund;
    },
  },
  watch: {
    // "$route.params.mode": {
    //   handler(n, o) {
    //     if (n) {
    //       this.query.showType = this.showTypes[this.$route.params.mode];
    //       this.getOrderList(1, 10);
    //     }
    //   },
    //   immediate: true,
    // },
  },
  created() {
    this.getHotList();
    this.departmentId = sessionStorage.departmentId;
  },
  mounted() {
    if (this.dynamicOrderRefund) {
      this.onRefundDetail(this.dynamicOrderRefund);
    }

    this.getDepartInfo();
    // this.getOrderList(1, 10);
  },
  methods: {
    settleDetail(i) {
      this.$api.base
        .settleDetail({
          orderId: i,
        })
        .then((res) => {
          if (res.message) {
            this.$message.warning("暂无开票信息");
          } else {
            this.dialog1 = true;
            this.settleDetail1 = res;
            try {
              this.settleDetail1.invoiceData = JSON.parse(this.settleDetail1.invoiceData);
            } catch (error) {}
            if (this.settleDetail1.invoiceImg) {
              this.settleDetail1.invoiceImg = this.settleDetail1.invoiceImg.split(",");
            }
            if (this.settleDetail1.settleImg) {
              this.settleDetail1.settleImg = this.settleDetail1.settleImg.split(",");
            }
          }
        });
    },
    lookBtn1(i) {
      this.settleDetail(i.id);
    },
    resetSearch1() {
      this.getOrderList();
    },
    resetSearch() {
      this.optionsValue1 = "";
      this.query.departmentId = this.optionsValue1;
      this.optionsValue = "0";
      this.query.showType = this.optionsValue;
      this.getOrderList(1, 10);
    },
    handleChange(e) {
      this.query.departmentId = e[e.length - 1];
      this.getOrderList(1, 10);
    },
    handleChange1(e) {
      this.query.showType = e;
      this.getOrderList(1, 10);
    },
    getDepartInfo() {
      this.$api.base.getDepartInfo().then((res) => {
        let arr1 = [res];
        function loop(obj) {
          let fixarr = [];
          obj.forEach((v1) => {
            let temp1 = {
              value: v1.id,
              label: v1.name,
              children: null,
            };
            if (v1.dtsDepartments && v1.dtsDepartments.length) {
              temp1.children = loop(v1.dtsDepartments);
            }
            fixarr.push(temp1);
          });
          return fixarr;
        }
        this.optionsList1 = loop(arr1);
        if (this.optionsList1 && this.optionsList1.length) {
          this.optionsValue1 = this.optionsList1[0].value;
          this.handleChange([this.optionsValue1]);
        }
      });
    },
    getHotList() {
      this.$api.base.HotGoods({ size: 4 }).then((response) => {
        this.hots = response.hotGoodsList.map((item) => {
          item.goods.retailPrice = Math.min(...item.products.map((i) => i.price));
          return item.goods;
        });
      });
    },
    getOrderList(page, size) {
      if (page != undefined) {
        this.query.page = page;
      }

      if (size != undefined) {
        this.query.size = size;
      }

      this.$api.base.OrderList(Object.assign({ ...this.query }, { page, size })).then((response) => {
        let orderList = response.data;

        orderList.forEach((item, index, arr) => {
          let dataObj = item.dataResults;
          let shipList = item.shipList;
          let fixarr1 = [];
          let fixarr2 = [];
          let orderGoods = [];
          for (let i in dataObj) {
            let arr1 = dataObj[i];
            if (arr1 && arr1.length) {
              arr1[0].rowspan = arr1.length;
              arr1[0].orderStatusText = this.orderDict[i];
              orderGoods = orderGoods.concat(arr1);
            }
          }
          orderGoods.forEach((v1) => {
            if (shipList.map((v) => v.orderGoodsId).indexOf(v1.id) === -1) {
              fixarr2.push(v1);
            } else {
              fixarr1.push(v1);
            }
          });
          item.goodsList = orderGoods;
          if (shipList && shipList.length) {
            item.goodsList[0].shipId = shipList[0].shipId;
            let shipIds = [];
            shipList.forEach((v1) => {
              let indexNum = shipIds.map((v2) => v2.shipId).indexOf(v1.shipId);
              if (indexNum === -1) {
                shipIds.push({
                  shipId: v1.shipId,
                  size: 1,
                  orderStatusText: v1.orderStatusText,
                  list: [v1],
                });
              } else {
                let item1 = shipIds[indexNum];
                item1.list.push(v1);
                item1.size += 1;
                item1.orderStatusText = v1.orderStatusText;
                shipIds[indexNum] = item1;
              }
            });
            if (fixarr2.length) {
              shipIds.push({
                shipId: "",
                size: fixarr2.length,
                orderStatusText: "",
                list: [],
              });
            }
            // this.shipList = shipIds;

            // if (this.$route.query.shipId) {
            //   if (this.shipList[0]) {
            //     this.ExpressTrace(id);
            //   }
            //   this.orderGoods = [];
            //   this.shipList.forEach((v2) => {
            //     if (this.$route.query.shipId == v2.shipId) {
            //       v2.list.forEach((v3) => {
            //         fixarr1.forEach((v4) => {
            //           if (v4.id == v3.orderGoodsId) {
            //             this.orderGoods.push(v4);
            //           }
            //         });
            //       });
            //     }
            //   });
            //   this.orderGoods[0].rowspan = this.orderGoods.length;
            // } else {
            //   this.orderGoods = fixarr2;
            // }
          }
          arr[index] = item;
        });

        this.orders = orderList;
        this.query.total = response.totalCount;
      });
    },
    onOrderPay(order) {
      this.$router.push({
        path: "/order/pay",
        query: {
          orderId: order.id,
          actualPrice: order.actualPrice,
          orderSn: order.orderSn,
        },
      });
    },
    onOrderCancel(order) {
      this.$api.base.OrderCancel({ orderId: order.id }).then((response) => {
        this.getOrderList();
      });
    },
    onOrderDelete(order) {
      this.$api.base.OrderDelete({ orderId: order.id }).then((response) => {
        this.getOrderList();
      });
    },
    onOrderConfirm(order) {
      this.$api.base.OrderConfirm({ orderId: order.id }).then((response) => {
        this.getOrderList();
      });
    },
    onOrderAgain(id) {
      this.$router.push({ path: "/product/detail/" + id });
    },
    onRefundDetail(id) {
      this.$api.base.RefundOrderQuery({ refundId: id }).then((response) => {
        this.form.refund = Object.assign(response.refundTrace, { consignee: response.orderInfo.consignee, mobile: response.orderInfo.mobile });
        this.dialog.refund = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selectTitle {
  padding: 0 20px;
}
.selectBtn1 {
  margin-left: 10px;
}
.button-text {
  color: #666;
  display: inline-block;
  padding: 0 10px;
  margin: 2px 0;
  &:first-of-type {
    display: block;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    border-radius: 40px;
    padding: 4px 0;
    text-align: center;
    width: 70px;
    margin: 2px auto;
  }
}
.zc-table-content-operate {
  padding: 10px 5px;
}
</style>
