/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 08:38:41
 * @LastEditTime: 2022-08-26 17:30:45
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: 封装网络请求
 * @FilePath: \SuperMall\src\common\axios.js
 */
import { MessageBox, Alert } from "element-ui";

import axios from "axios";
import Qs from "qs";

import { encode } from "../crypto";

const createToken = (timestamp) => {
  const sign = "yuze";
  return encode(sign + timestamp);
};

const commonTip = (msg) => {
  console.log("this is commonTip");
};

const commonLogin = () => {
  console.log("this is commonLogin");
};

const errorStatus = (status, other) => {
  switch (status) {
    case 401:
      // 401: 未登录状态，跳转登录页
      break;
    case 403:
      // 403: 登录过期，请重新登录
      break;
    case 404:
      // 404: 请求不存在
      break;
    default:
  }
};

const statusCallback = {};

export const setStatusCallback = (status, method) => {
  if (method && typeof method === "function") {
    statusCallback[status] = method;
  }
};

const responseFormat = {};

const getResponseFormat = (url) => {
  for (let key in responseFormat) {
    if (~url.indexOf(key)) {
      return responseFormat[key];
    }
  }
};

export const setResponseFormat = (key, format) => {
  responseFormat[key] = format;
};

axios.defaults.timeout = 1000 * 30;

axios.defaults.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: "brackets" });

axios.interceptors.request.use(
  (config) => {
    /* let user = JSON.parse(sessionStorage.getItem('SMC_SESSION') || '{}');
	
			if (user && user.access_token)
			{
				config.headers[ 'Authorization' ] = 'Bearer ' + user.access_token;
			} */

    config.headers["x-dts-token"] = localStorage.token;
    // const timestamp = new Date().getTime();

    // config.headers["tokens"] = createToken(timestamp);
    // config.headers["timestamp"] = timestamp;

    return config;
  },
  (error) => Promise.error(error)
);

axios.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) {
      return response;
    } else if (response.status === 200) {
      let format = getResponseFormat(response.config.url);

      format && (response = format(response.data));

      if (response.status === 404) {
        uni.navigateTo({ url: "/page/404" });
        return Promise.resolve();
      } else if (response.status === 501) {
        localStorage.token = "";
        uni.showToast({ title: response.message });
        uni.navigateTo({ url: "/user/login" });
        return Promise.reject(response);
      } else if (response.status === 0) {
        return response;
      } else if (statusCallback[response.status]) {
        let promise = Promise.reject(response);
        setTimeout(() => statusCallback[response.status](response));
        return promise;
      } else {
        MessageBox.alert('<div class="zc-message-tip"><h2>信息提示</h2><p>' + response.message + "</p></div>", "", {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确定",
          showClose: false,
        }).then((res) => {});

        return Promise.reject(response);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    const { response } = error;

    if (response) {
      console.log("axios.interceptors.response:error", response);
      errorStatus(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      statusCallback["OFFLINE"] && statusCallback["OFFLINE"]({ status: 0, message: "请稍后再试" });
      return Promise.reject({ status: 0, message: "网络异常请稍后再试", size: "4.5vw" });
    }
  }
);

const types = {
  string: String,
  number: Number,
  boolean: Boolean,
  date: Date,
};

/**
 * @description: 解析模式请求参数
 * @param {*}
 * @return {*}
 */
const getFieldAndDefault = (field) => {
  let result = /:(\S*)/i.exec(field);

  if (result && result[1]) {
    let type = types[result[1].toLowerCase()];

    if (type) {
      result = /(\S+)=(\S*):/i.exec(field);
      return { key: result[1], value: type(result[2]) };
    }
  }

  result = /(\S+)=(\S*):*/i.exec(field);

  return result && result[1] && result[2] ? { key: result[1], value: result[2] } : undefined;
};

/**
 * @description: 处理restFul风格接口路由及参数
 * @param {*}
 * @return {*}
 */
const formatRequestData = (url, params, fields) => {
  params = params || {};

  if (fields && fields.length) {
    fields.forEach((field) => {
      let result = getFieldAndDefault(field);

      if (result && params[result.key] === undefined) {
        params[result.key] = result.value;
      }
    });
  }

  for (let key in params) {
    let regex = new RegExp("{" + key + "}", "gi");

    url = url.replace(regex, ($0) => {
      let value = params[key];
      delete params[key];

      return value;
    });
  }

  return { target: url, data: params };
};

export const get = (url, params, fields, format, bag) => {
  let { target, data } = formatRequestData(url, params, fields);

  return new Promise((resolve, reject) => {
    axios
      .get(target, { params: data })
      .then((response) => {
        format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
        bag && typeof bag === "function" && bag(response);
      })
      .catch((error) => reject(error));
  });
};

export const post = (url, params, fields, format, bag) => {
  let { target, data } = formatRequestData(url, params, fields);

  return new Promise((resolve, reject) => {
    axios
      .post(target, data)
      .then((response) => {
        format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
        bag && typeof bag === "function" && bag(response);
      })
      .catch((error) => reject(error));
  });
};

export const put = (url, params, fields, format, bag) => {
  let { target, data } = formatRequestData(url, params, fields);

  return new Promise((resolve, reject) => {
    axios
      .put(target, data)
      .then((response) => {
        format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
        bag && typeof bag === "function" && bag(response);
      })
      .catch((error) => reject(error));
  });
};

export const remove = (url, params, fields, format, bag) => {
  let { target, data } = formatRequestData(url, params, fields);

  return new Promise((resolve, reject) => {
    axios
      .delete(target, data)
      .then((response) => {
        format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
        bag && typeof bag === "function" && bag(response);
      })
      .catch((error) => reject(error));
  });
};

export const postDown = (url, params, fields, format, bag) => {
  let { target, data } = formatRequestData(url, params, fields);

  return new Promise((resolve, reject) => {
    axios
      .post(target, data, { responseType: "blob" })
      .then((response) => {
        format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
        bag && typeof bag === "function" && bag(response);
      })
      .catch((error) => reject(error));
  });
};

export const getDown = (url, params, fields, format, bag) => {
  let { target, data } = formatRequestData(url, params, fields);

  return new Promise((resolve, reject) => {
    axios
      .get(target, { params: data, responseType: "blob" })
      .then((response) => {
        format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
        bag && typeof bag === "function" && bag(response);
      })
      .catch((error) => reject(error));
  });
};
